<template>
  <div class="container">
    <div class="row" v-if="superAdmin">
      <div class="col-sm-12 d-flex justify-content-end">
        <modal-btn
          target="#add-question"
          btn-class="btn-info btn-sm"
          title="إضافة"
        >
          <span>إضافة سؤال</span>
          <span class="fa fa-plus"></span>
        </modal-btn>
      </div>
    </div>
    <hr v-if="superAdmin" />
    <div
      class="mb-3"
      v-for="question in questions"
      :key="question.id"
      :id="`question-row${question.id}`"
    >
      <div class="edit-container" v-if="superAdmin">
        <div class="edit">
          <i
            class="fa fa-trash-alt btn btn-sm btn-outline-info text-warning border-0 pm-0 pr-1"
            data-toggle="modal"
            :data-target="`#delete-question${question.id}`"
          ></i>
          <i
            class="fa fa-edit btn btn-sm btn-outline-info text-light border-0 pm-0"
            data-toggle="modal"
            :data-target="`#edit-question${question.id}`"
          ></i>
          <teleport to="#app">
            <items-destroyer
              title="حذف سؤال"
              :target-id="`delete-question${question.id}`"
              :url="`questions/${question.id}`"
              :row-id="`question-row${question.id}`"
              @deleted="deleted"
            >
              هل أنت متأكد من حذف السؤال ؟
            </items-destroyer>
            <confirm-model
              :target-id="`edit-question${question.id}`"
              title="تعديل سؤال"
              confirm-text="تعديل"
              @confirmed="clickById(`edit-question-submit${question.id}`)"
            >
              <form @submit.prevent="update">
                <input type="hidden" name="id" :value="question.id" />
                <input type="hidden" name="_method" value="PUT" />
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <label>السؤال</label>
                      <form-text-area
                        :id="`question${question.id}`"
                        name="question"
                        placeholder="السؤال"
                        :value="question.question"
                      ></form-text-area>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <label>الإجابة</label>
                      <form-text-area
                        :id="`answer${question.id}`"
                        name="answer"
                        placeholder="الإجابة"
                        :value="question.answer"
                      ></form-text-area>
                    </div>
                  </div>
                </div>
                <button
                  hidden
                  :id="`edit-question-submit${question.id}`"
                ></button>
              </form>
            </confirm-model>
          </teleport>
        </div>
      </div>
      <div
        class="faq-dialog"
        data-toggle="collapse"
        :data-target="`#qa${question.id}`"
      >
        <question-mark class="icon"></question-mark>
        <div class="question">{{ question.question }}</div>
      </div>
      <div class="faq-dialog collapse" :id="`qa${question.id}`">
        <check-icon class="icon left"></check-icon>
        <div class="answer">{{ question.answer }}</div>
      </div>
    </div>
    <div class="row" v-if="noQuestions && !$store.getters.shouldReload">
      <div class="col-md-12">
        <div class="w-100 border shadow jumbotron text-center rounded-circle">
          <h5>لا يوجد اسئلة</h5>
        </div>
      </div>
    </div>
    <teleport to="#app" v-if="superAdmin">
      <confirm-model
        target-id="add-question"
        title="إضافة سؤال"
        confirm-text="إضافة"
        @confirmed="clickById('add-question-submit')"
      >
        <form @submit.prevent="store">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <label>السؤال</label>
                <form-text-area
                  id="question"
                  placeholder="السؤال"
                ></form-text-area>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label>الإجابة</label>
                <form-text-area
                  id="answer"
                  placeholder="الإجابة"
                ></form-text-area>
              </div>
            </div>
          </div>
          <button hidden id="add-question-submit"></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import CheckIcon from "../../SVG/CheckIcon";
import QuestionMark from "../../SVG/QuestionMark";
import Storage from "@/common/Storage";
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormTextArea from "@/components/Forms/FormTextArea";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import arrays from "@/common/arrays";

export default {
  components: {
    ItemsDestroyer,
    FormTextArea,
    ConfirmModel,
    ModalBtn,
    QuestionMark,
    CheckIcon,
  },
  data() {
    return {
      questions: [],
    };
  },
  computed: {
    noQuestions() {
      return !this.questions || this.questions.length === 0;
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  methods: {
    async setQuestions() {
      this.questions = Storage.get("questions");
      if (!!this.questions) return (this.questions = this.questions.data);
      const response = await http.send("questions", { _method: "GET" });
      if (http.responseAccepted(response)) this.saveQuestions(response.data);
    },
    async store(e) {
      const formData = new FormData(e.target);
      if (this.checkFields(formData))
        return http.popupMessage("error", messages.checkFields);
      const response = await http.send("questions", formData);
      if (http.responseAccepted(response)) {
        this.saveQuestions(response.data);
        http.popupMessage("success", messages.saved);
        this.hideModal("#add-question");
        $_("#add-question .form-control").val("");
        $_("#add-question .selectpicker").selectpicker("val", "");
      }
    },
    async update(e) {
      const formData = new FormData(e.target);
      const id = formData.get("id");
      if (!id) return console.error("no question specified");
      if (this.checkFields(formData))
        return http.popupMessage("error", messages.checkFields);
      const response = await http.send(`questions/${id}`, formData);
      if (http.responseAccepted(response)) {
        this.saveQuestions(response.data);
        http.popupMessage("success", messages.updated);
        this.hideModal(`#edit-question${id}`);
      }
    },
    deleted(id) {
      if (!id || !this.questions || this.questions.length === 0) return;
      this.questions = arrays.removeById(this.questions, id);
    },
    checkFields(formData) {
      return !formData || !formData.get("question") || !formData.get("answer");
    },
    saveQuestions(data) {
      if (!data) return;
      this.questions = data.questions;
      Storage.set("questions", data.questions, 3); // commented
    },
  },
  async created() {
    await this.setQuestions();
  },
};
</script>
<style scoped>
.answer,
.question {
  border-radius: 20px;
  min-height: 50px;
  padding: 14px;
  width: 100%;
  text-align: center;
  box-shadow: 0 0 9px #45cec8;
  font-weight: bold;
}

.answer {
  color: #217846;
  background-color: #d7f3e3;
  border-right: 5px solid #897172;
}

.question {
  color: #e9eef2;
  background-color: #5583ae;
  border-left: 5px solid #34597b;
}

.faq-dialog {
  margin-top: 0.25rem;
  position: relative;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: -19px;
}

.icon.left {
  left: 0;
}

.rounded-circle {
  border-radius: 50rem !important;
}

.edit-container {
  position: relative;
  width: 100%;
  z-index: 1000;
}

.edit {
  position: absolute;
  left: 3%;
}
</style>
