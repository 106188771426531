const mutations = {
  setShouldReload(state, status) {
    state.shouldReload = status;
  },
  setNotes(state, notes) {
    state.notes = notes;
  },
  setCities(state, cities) {
    state.cities = cities;
  },
  setColors(state, colors) {
    state.colors = colors;
  },
  setAreas(state, areas) {
    state.areas = areas;
  },
  setUserActivation(state, payload) {
    state.userActivationCount = payload;
  },
  setInsurances(state, payload) {
    state.insurances = payload;
  },
  setCounters(state, payload) {
    state.counters = payload;
  },
  setPartners(state, payload) {
    state.partners = payload;
  },
  setIntendedUrl(state, intendedRoute) {
    state.intendedRoute = intendedRoute;
  },
  setPosition(state, position) {
    state.position = position;
  },
};

export default mutations;
