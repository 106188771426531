<template>
  <PageLoader v-if="shouldReload" />
  <router-view />
  <!--logout modal-->
  <logout v-if="!!authorized"></logout>

  <!--should update data box-->
  <UpdateMyData v-if="shouldUpdateData" />
</template>

<script>
import PageLoader from "./components/UI/PageLoader";
import Logout from "@/components/Pages/Auth/Logout";
import UpdateMyData from "@/components/Users/UpdateMyData";
import Storage from "@/common/Storage";

export default {
  components: { UpdateMyData, Logout, PageLoader },
  computed: {
    shouldReload() {
      return this.$store.getters.shouldReload;
    },
    authorized() {
      return this.$store.getters.authorized;
    },
    shouldUpdateData() {
      return this.$store.getters.shouldUpdateData;
    },
  },
  created() {
    Storage.removeStartWith("bids-interval");
  },
  mounted() {
    window.addEventListener("online", () => {
      http.successMessage("تم استعادة الشبكة");
      this.$store.dispatch("connectToPublicChannels");
      if (this.authorized) {
        this.$store.dispatch("connectToUserChannels");
      }
    });

    window.addEventListener("offline", () => {
      http.errorMessage("اتصال الشبكة لديك غير مستقر");
    });
  },
};
</script>
<style>
@import "./assets/css/app.css";
</style>
