import { createStore } from "vuex";
import auth from "./modules/auth";
import main from "./modules/main";
import auctions from "./modules/auctions";

const store = createStore({
  modules: { main, auth, auctions },
});

export default store;
