const userRoutes = [
  {
    path: "/user",
    name: "user",
    redirect: { name: "user.dashboard" },
    component: () => import("@/components/Users/UserHome"),
    meta: { requiresAuth: true, suspended: true, user: true },
    children: [
      {
        path: "dashboard",
        name: "user.dashboard",
        meta: { title: "لوحة التحكم" },
        component: () => import("@/components/Pages/User/UserDashboard"),
      },
      {
        path: "/auctions",
        meta: { requiresAuth: true, title: "المزادات" },
        component: () => import("@/components/Auctions/AuctionsHome"),
        children: [
          {
            path: "following",
            name: "client.auctions.following",
            meta: { title: "المزادات التي اتابعها" },
            component: () =>
              import("@/components/Pages/User/Auctions/Following"),
          },
          {
            path: "won",
            name: "client.auctions.won",
            meta: { title: "المزادات التي فزت بها" },
            component: () => import("@/components/Pages/User/Auctions/Won"),
          },
          {
            path: "user-unpaid",
            name: "client.auctions.user-unpaid",
            meta: { title: "مزادات بحاجة الى تسديد" },
            component: () =>
              import("@/components/Pages/User/Auctions/UserUnpaid"),
          },
        ],
      },
      {
        path: "insurance",
        name: "user.insurance",
        meta: { title: "التأمين" },
        component: () => import("@/components/Pages/User/UserInsurance"),
      },
      {
        path: "profile",
        name: "user.profile",
        meta: { title: "بياناتي" },
        component: () => import("@/components/Pages/User/UserProfile"),
      },
      {
        path: "activate",
        name: "client.activate",
        meta: { title: "تفعيل الحساب" },
        component: () => import("@/components/Pages/Admin/ClientActivate"),
      },
    ],
  },
];

export default userRoutes;
