import Storage from "@/common/Storage";
import types from "@/common/types";

export default {
  emits: ["models-changed"],
  data() {
    return {
      manufacturers: [],
      models: [],
      manufacturerName: "",
      modelName: "",
    };
  },
  methods: {
    async setManufacturerList() {
      const storedManufacturers = Storage.get("manufacturers");

      if (!!storedManufacturers && !!storedManufacturers.data)
        return (this.manufacturers = types.objectToArray(
            storedManufacturers.data
        ));

      const response = await http.send("manufacturers", {_method: "GET"});
      if (http.responseAccepted(response)) {
        this.manufacturers = response.data.manufacturers;
        Storage.set("manufacturers", this.manufacturers, 60); // commented
      }
    },
    async setModelsList(id) {
      if (!id) {
        return false;
      }

      this.setManufacturerName(id);
      const response = await http.send("models", {_method: "GET", id});
      if (http.responseAccepted(response)) {
        this.models = response.data.models;
        this.$emit("models-changed", this.models);
      }
    },
    setManufacturerName(manufacturerId) {
      if (!this.manufacturers || this.manufacturers.length === 0)
        return manufacturerId;
      let manufacturers = types.objectToArray(this.manufacturers);
      manufacturers = manufacturers.find(
          (manufacturer) => parseInt(manufacturer.id) === parseInt(manufacturerId)
      );
      return (this.manufacturerName = !!manufacturers
          ? manufacturers.content
          : "");
    },
    setModelName(modelId) {
      if (!this.models || this.models.length === 0) {
        return modelId;
      }
      const model = this.models.find(
          (model) => parseInt(model.id) === parseInt(modelId)
      );
      return (this.modelName = !!model ? model.content : "");
    },
    getManufacturerByName(name) {
      if (!name || !this.manufacturers || this.manufacturers.length === 0) {
        return null;
      }

      return this.manufacturers.find((item) => {
        return item.content === name;
      });
    },
    getModelByName(name) {
      if (!name || !this.models || this.models.length === 0) {
        return null;
      }

      return this.models.find((item) => {
        return item.content === name;
      });
    },
  },
}