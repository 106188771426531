const CryptoJS = require("crypto-js");
import types from "@/common/types";

class Crypt {
  constructor() {
  }


  encrypt(encrypt) {
    if (!!encrypt)
      try {
        encrypt = CryptoJS.AES.encrypt(JSON.stringify({encrypt})).toString();
      } catch (e) {
        try {
          encrypt = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypt));
        } catch (e) {
          encrypt = '';
        }
      }
    return encrypt;
  }

  decrypt(encrypted) {
    if (!!encrypted) {
      if (!isNaN(encrypted))
        // if number
        encrypted = encrypted.toString();
      try {
        encrypted = JSON.parse(CryptoJS.AES.decrypt(encrypted).toString(CryptoJS.enc.Utf8)).content;
      } catch (e) {
        try {
          encrypted = CryptoJS.enc.Base64.parse(encrypted).toString(CryptoJS.enc.Utf8);
        } catch (e) {
          encrypted = '';
        }
      }
    }
    return encrypted;
  }

  encryptData(data) {
    const temp = {};
    if (types.isObject(data))
      for (const item in data)
        if (types.isObject(data[item])) {
          temp[this.encrypt(item)] = this.encryptData(data[item]);
        } else {
          temp[this.encrypt(item)] = this.encrypt(data[item]);
        }
    return types.isObject(temp) ? temp : '';
  }

  decryptData(data) {
    const temp = {};
    if (types.isObject(data))
      for (const item in data)
        if (types.isObject(data[item])) {
          temp[this.decrypt(item)] = this.decryptData(data[item]);
        } else {
          let value = this.decrypt(data[item]);
          const index = this.decrypt(item);
          temp[index] = !(!value || typeof value === "boolean" || isNaN(value)) && this.canParse(index) ? parseInt(value) : value;
        }
    return types.isObject(temp) ? temp : '';
  }

  canParse(index) {
    return !!index && !['mobile'].includes(index);
  }
}

const crypt = new Crypt();

export default crypt;


