export default {
  methods: {
    fire(options, callback = "") {
      this.$swal
        .fire({
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "تأكيد",
          cancelButtonText: "إلغاء",
          customClass: {
            confirmButton: "btn btn-sm py-2 px-3",
            cancelButton: "btn btn-sm py-2 px-3",
          },
          ...options,
        })
        .then(async (result) => {
          if (!!callback && result.isConfirmed) {
            callback(result);
          }
        });
    },
    fireMessage(icon = "success") {
      this.fire({
        icon,
        showCancelButton: false,
        confirmButtonText: "إنهاء",
        text: messages.updated,
        customClass: {
          cancelButton: `btn btn-sm py-2 px-3`,
        },
      });
    },
  },
};
