import Storage from "@/common/Storage";
import types from "@/common/types";

const companies = {
  data() {
    return {
      companies: [],
      selectedCompany: "",
      selectedCompanyName: "",
    };
  },
  methods: {
    async setCompanies() {
      const storedCompanies = Storage.get("companies-list");
      if (types.isObject(storedCompanies)) {
        this.companies = types.objectToArray(storedCompanies.data);
      } else {
        const response = await http.send("companies-list", { _method: "GET" });
        if (http.responseAccepted(response)) {
          this.companies = response.data.items;
          Storage.set("companies-list", this.companies, 3); //done
        }
      }
    },
    selectCompany(id) {
      this.selectedCompany = this.companies.find((company) => {
        return parseInt(company.id) === parseInt(id);
      });
      if (!!this.selectedCompany)
        this.selectedCompanyName = this.selectedCompany.content;
    },
  },
  async created() {
    await this.setCompanies();
  },
};

export default companies;
