<template>
  <textarea :id="id" :name="defaultName" :placeholder="placeholder" class="form-control rounded-0" :rows="defaultRows"
            :cols="cols" :value="value" :class="inputClass"></textarea>
  <error-label v-if="error" :id="id"></error-label>
</template>

<script>
import ErrorLabel from "@/components/UI/ErrorLabel";
export default {
  components: {ErrorLabel},
  props: {
    id: {required: true},
    name: {},
    inputClass: {},
    rows: {type: Number, default: 5},
    cols: {type: Number},
    value: {},
    placeholder: {default: 'الحقل'},
    error: {default: true},
  },
  computed: {
    defaultName() {
      return !!this.name ? this.name : this.id;
    },
    defaultRows() {
      return !!this.rows ? this.rows : 8;
    },
    errorId() {
      return this.id + '_error';
    }
  }
}
</script>

<style scoped>
textarea {
  resize: none;
}
</style>