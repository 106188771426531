<template>
  <div class="container">
    <div class="row justify-content-end" v-if="!stick">
      <div class="col-sm-1 p-1" v-if="showPerPage">
        <BootstrapSelect
          id="pagination"
          :options="pages"
          :search="false"
          :selected="perPage"
          :empty-option="false"
          @changed="selectPerPage"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-xs-1 p-1" v-for="(link, index) in links" :key="index">
        <button
          type="button"
          class="link-pagination"
          :class="{ active: link.active }"
          @click="setLink(link)"
        >
          {{ link.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BootstrapSelect from "@/components/Forms/BootstrapSelect";

export default {
  components: { BootstrapSelect },
  emits: ["link-changed"],
  props: {
    links: { required: true, default: [] },
    url: { required: true },
    total: { required: true },
    lastPage: { required: true, default: 0 },
    stick: { type: Number },
    defaultPerPage: { type: Number },
    showPerPage: { type: Boolean, default: true },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 15,
    };
  },
  watch: {
    currentPage() {
      this.setPaginationUrl();
    },
  },
  computed: {
    moreThanTen() {
      return !!this.total && parseInt(this.total) > 10;
    },
    pages() {
      return [10, 15, 25, 50, 75, 100, 500];
    },
  },
  methods: {
    init() {
      this.currentPage = 1;
      if (!!this.stick) {
        this.setPerPage(this.stick);
      } else if (!!this.defaultPerPage) {
        this.setPerPage(this.defaultPerPage);
      }
    },
    setLink(link) {
      if (!link || link.active) return;
      if (!isNaN(link.label)) {
        this.currentPage = link.label;
      } else {
        let currentPage = parseInt(this.currentPage);
        if (link.label === "السابق") {
          if (currentPage > 1) currentPage--;
        } else if (link.label === "التالي") {
          if (isNaN(currentPage)) return;
          if (currentPage < parseInt(this.lastPage)) currentPage++;
        }
        if (currentPage !== parseInt(this.currentPage)) {
          this.currentPage = currentPage;
        }
      }
    },
    selectPerPage(page) {
      this.setPerPage(page);
      this.setPaginationUrl();
    },
    setPerPage(page) {
      this.perPage = parseInt(page);
    },
    setPaginationUrl() {
      if (!!this.currentPage)
        this.$emit("link-changed", {
          url: `${this.url}?page=${this.currentPage}`,
          perPage: this.stick || this.perPage,
        });
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.link-pagination {
  background-color: transparent;
  border: 1px solid #98a6ad;
  min-width: 40px;
  border-radius: 5rem 1rem 5rem 5rem;
}

.link-pagination.active {
  background-color: #6bbbf0;
  color: white;
}
</style>
