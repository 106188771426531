const auctionsRoutes = [
  {
    path: "/admin/auctions",
    meta: {requiresAuth: true, admin: true, title: "المزادات"},
    component: () => import("@/components/Auctions/AuctionsHome"),
    children: [
      {
        path: "all",
        name: "admin.auctions.all",
        meta: {title: "جميع المزادات", superAdmin: true},
        component: () => import("@/components/Pages/Auctions/Lists/All"),
      },
      {
        path: "current",
        name: "admin.auctions.current",
        meta: {title: "المزادات الحالية"},
        component: () => import("@/components/Pages/Auctions/Lists/Current"),
      },
      {
        path: "stumbled",
        name: "admin.auctions.stumbled",
        meta: {title: "المزادات المتعثرة"},
        component: () => import("@/components/Pages/Auctions/Lists/Stumbled"),
      },
      {
        path: "unpaid",
        name: "admin.auctions.unpaid",
        meta: {title: "بحاجة الى تسديد"},
        component: () => import("@/components/Pages/Auctions/Lists/Unpaid"),
      },
      {
        path: "need-invoice",
        name: "admin.auctions.need-invoice",
        meta: {title: "مزادات اصدار الفواتير", superAdmin: true},
        component: () =>
          import("@/components/Pages/Auctions/Lists/NeedInvoice"),
      },
      {
        path: "need-invoice-checking",
        name: "admin.auctions.need-invoice-checking",
        meta: {title: "مزادات تدقيق الفواتير", superAdmin: true},
        component: () =>
          import("@/components/Pages/Auctions/Lists/NeedInvoiceChecking"),
      },
      {
        path: "sold-externally",
        name: "admin.auctions.sold-externally",
        meta: {title: "المزادات المسحوبة", superAdmin: true},
        component: () =>
          import("@/components/Pages/Auctions/Lists/SoldExternally"),
      },
      {
        path: "not-received",
        name: "admin.auctions.not-received",
        meta: {title: "المزادات غير المستلمة"},
        component: () =>
          import("@/components/Pages/Auctions/Lists/NotReceived"),
      },
      {
        path: "received",
        name: "admin.auctions.received",
        meta: {title: "المزادات المستلمة"},
        component: () => import("@/components/Pages/Auctions/Lists/Received"),
      },
      {
        path: "unpaid-floor",
        name: "admin.auctions.unpaid-floor",
        meta: {title: "ارضيات غير مدفوعة"},
        component: () =>
          import("@/components/Pages/Auctions/Lists/UnpaidFloor"),
      },
      {
        path: "deleted",
        name: "admin.auctions.deleted",
        meta: {title: "المزادات المشطوبة", superAdmin: true},
        component: () => import("@/components/Pages/Auctions/Lists/Deleted"),
      },
      {
        path: "pending",
        name: "auctions.pending",
        meta: {title: "المزادات قيد الانتظار", superAdmin: true},
        component: () => import("@/components/Pages/Auctions/AuctionsPending"),
      },
      {
        path: "create",
        name: "auctions.create",
        meta: {title: "إنشاء مزاد", canCreateAuction: true},
        component: () => import("@/components/Pages/Auctions/CreateAuction"),
      },
      {
        path: "created",
        name: "auctions.created",
        meta: {title: "تم الإنشاء", canCreateAuction: true},
        component: () => import("@/components/Pages/Auctions/AuctionCreated"),
      },
      {
        path: "approving",
        name: "auctions.approving",
        meta: {title: "موافقة المزادات", superAdmin: true},
        component: () =>
          import("@/components/Pages/Auctions/ApprovingRequests"),
      },
      {
        path: ":auction",
        name: "auctions.edit",
        meta: {title: "تعديل المزاد"},
        redirect: {name: "edit.auction.data", canUpdateAuction: true},
        component: () => import("@/components/Auctions/EditAuction"),
        children: [
          {
            path: "edit",
            name: "edit.auction.data",
            meta: {title: "بيانات المزاد", canUpdateAuction: true},
            component: () =>
              import("@/components/Pages/Auctions/EditAuctionData"),
          },
          {
            path: "status",
            name: "auctions.status",
            meta: {title: "حالة المزاد", canUpdateAuction: true},
            component: () =>
              import("@/components/Pages/Auctions/AuctionStatus"),
          },
          {
            path: "images",
            name: "auctions.images",
            meta: {title: "صور المزاد", canUpdateAuction: true},
            component: () =>
              import("@/components/Pages/Auctions/AuctionImages"),
          },
          {
            path: "notes",
            name: "auctions.notes",
            meta: {title: "ملاحظات المزاد", canUpdateAuction: true},
            component: () => import("@/components/Pages/Auctions/EditNotes"),
          },
          {
            path: "bids",
            name: "auctions.bids",
            meta: {title: "سجل المزاودات", superAdmin: true},
            component: () => import("@/components/Pages/Auctions/BidsLog"),
          },
          {
            path: "log",
            name: "auctions.log",
            meta: {title: "سجل المزاودات", superAdmin: true},
            component: () => import("@/components/Pages/Auctions/AuctionLog"),
          },
        ],
      },
      {
        path: "/auctions/:auction/invoice",
        name: "invoices.create",
        meta: {title: "إصدار فاتورة", canUpdateAuction: true},
        component: () => import("@/components/Pages/Invoices/CreateInvoice"),
      },
      {
        path: "/invoices",
        name: "auction.invoice",
        redirect: {name: "invoices.list"},
        component: () => import("@/components/Invoices/InvoicesHome"),
        children: [
          {
            path: ":company/list",
            name: "invoices.list",
            meta: {title: "الفواتير", superAdmin: true},
            component: () => import("@/components/Pages/Invoices/InvoicesList"),
          },
          {
            path: ":invoice/edit",
            name: "invoices.edit",
            meta: {title: "تعديل فاتورة", canUpdateAuction: true},
            component: () => import("@/components/Pages/Invoices/EditInvoice"),
          },
        ],
      },
      {
        path: "not-valid",
        name: "auctions.not.valid",
        meta: {title: "جميع المزادات غير المكتلمة", superAdmin: true},
        component: () => import("@/components/Pages/Auctions/NotValid"),
      },
      {
        path: "not-authorized",
        name: "auctions.not.authorized",
        meta: {title: "مزادات بحاجة الى تفويض", superAdmin: true},
        component: () =>
          import("@/components/Pages/Auctions/NeedAuthorization"),
      },
      {
        path: "just-authorized",
        name: "auctions.just.authorized",
        meta: {title: "مزادات تفويض فقط", superAdmin: true},
        component: () =>
          import("@/components/Pages/Auctions/JustAuthorization"),
      },
      {
        path: "need-approving",
        name: "auctions.need.approving",
        meta: {title: "مزادات بحاجة الى موافقة", superAdmin: true},
        component: () =>
          import("@/components/Pages/Auctions/ApprovingRequests"),
      },
    ],
  },
];

export default auctionsRoutes;
