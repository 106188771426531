import crypt from "@/common/crypter";
import arrays from "@/common/arrays";
import store from "@/store";

class storage {
  timeouts = [];

  constructor() {}

  set(key, data, minutes = 0, actionOnClear = "", dataOnClear = "") {
    if (!!key) {
      key = crypt.encrypt(key);
      try {
        let storage = {};
        minutes = parseInt(minutes);
        let timeout = minutes > 0 ? minutes : 0;
        /** CLEAR ITEM AFTER SPECIFIC MINUTES "IN MILLISECONDS" **/
        if (timeout > 0) {
          // Convert to milliseconds.
          timeout = timeout * 60000;
          this.timeouts.push(
            setTimeout(async () => {
              this.remove(crypt.decrypt(key));
              if (!!actionOnClear)
                await store.dispatch(actionOnClear, dataOnClear);
            }, timeout)
          );
        } else if (timeout === -1) {
          storage["storage_forever"] = 1;
        }

        storage["data"] = data;
        let date = new Date();
        date.setMinutes(date.getMinutes() + minutes);
        storage["storage_expired"] = date;
        localStorage.setItem(key, JSON.stringify(crypt.encryptData(storage)));
      } catch {
        localStorage.removeItem(key);
      }
    } else {
      return false;
    }
  }

  get(key) {
    if (!key) {
      return false;
    }

    key = crypt.encrypt(key);
    let data;
    try {
      data = JSON.parse(localStorage.getItem(key));
      data = crypt.decryptData(data);
    } catch {
      localStorage.removeItem(key);
    }
    return data;
  }

  remove(key) {
    return !key ? false : localStorage.removeItem(crypt.encrypt(key));
  }

  removeStartWith(key) {
    if (!key) {
      return false;
    }

    const allKeys = Object.keys(localStorage);
    for (const item in allKeys) {
      const storage = crypt.decrypt(allKeys[item]);
      if (!!storage && storage.startsWith(key))
        localStorage.removeItem(allKeys[item]);
    }
  }

  clear(force = false) {
    const allKeys = Object.keys(localStorage);
    for (const item in allKeys) {
      const data = Storage.get(crypt.decrypt(allKeys[item]));

      if (
        force ||
        !data ||
        !data.storage_forever ||
        parseInt(data.storage_forever) === 0
      ) {
        localStorage.removeItem(allKeys[item]);
        for (let i = 0; i < this.timeouts.length; i++) {
          const item = this.timeouts[i];
          clearTimeout(item);
          this.timeouts = arrays.removeByValue(this.timeouts, item);
        }
      }
    }
  }

  /**
   * To return the object without the extra keys
   * @param object
   * @returns {*}
   */
  removeStorageKeys(object) {
    if (!object && Object.entries(object).length === 0) {
      return object;
    }

    // remove the extra keys
    delete object["storage_expired"];
    delete object["storage_forever"];

    return object;
  }
}

const Storage = new storage();
export default Storage;
