import types from "@/common/types";

class Random {
  string(length) {
    let result = ''
    let characters = 'AaBbCcDdEeFeGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz123456789'

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length))
    }

    return result
  }

  number(length) {
    if (!length || isNaN(length)) {
      return length
    }

    let max = 10
    let lengthPlus = 10

    for (let i = 2; i < length; i++) {
      // starts with 2 cause the '1' will not affect on multiplying
      // suppose the length = "3" first time when length on (index 0 then index 1 then index 2 and out on index 3) => length 3 => 3 multiplying times
      max *= 10
      lengthPlus *= 10
    }

    return Math.floor(Math.random() * lengthPlus) + max
  }

  list(current, options = {}) {
    if (!current) {
      return []
    }

    options = !types.isObject(options) ? {} : options
    let list = []
    const diff = !!options.diff ? parseInt(options.diff) : 100
    const addFutureYears = !!options.future ? parseInt(options.future) : 0
    let start = Math.abs(current - diff)

    while (start <= current) {
      list.unshift(start++)
    }

    if (addFutureYears > 0) {
      let futureYears = current
      for (let year = 0; year < addFutureYears; year++) {
        list.unshift(++futureYears)
      }
    }

    if (options.asc) {
      list = list.reverse()
    }

    return list
  }
}

const random = new Random()
export default random