import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const auth = {
  state() {
    return {
      shouldReload: false,
      shouldReloadMsg: "",
      notes: [],
      cities: [],
      colors: [],
      areas: [],
      userActivationCount: 0,
      insurances: [],
      counters: [],
      partners: [],
      intendedRoute: "",
      dimensions: { y: 0, x: 0 },
      position: "",
    };
  },
  getters,
  actions,
  mutations,
};

export default auth;
