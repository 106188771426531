class Types {
  constructor() {
  }

  isObject(object) {
    return !!object && ['object', 'array'].includes(typeof (object)) && Object.entries(object).length > 0;
  }

  isEvent(e) {
    return !!e && !!e.target;
  }

  objectToArray(object) {
    if (!object || Object.entries(object).length === 0 || Array.isArray(object))
      return object;
    return Object.keys(object).map(item => object[item])
  }

  checkY(string) {
    if (!string || typeof (string) !== 'string')
      return false;
    return string.toLowerCase() === 'y'
  }
}

const data = new Types()
export default data