<template>
  <div class="container" v-if="!!auctionData">
    <div class="row" :class="border" :id="rowId">
      <router-link :to="adminType ? editLink : bidLink" class="col-md-3 pm-0" @click="redirect">
        <img :src="imagePath" class="wh-100" :alt="auctionAltText">
      </router-link>
      <div class="col-md-9 info pt-lg-2">
        <div class="card h-100 card-default">
          <div class="card-header card-default">
            <div class="w-100 d-flex justify-content-between flex-wrap">
              <router-link class="text-decoration-none" :to="adminType ? editLink : bidLink">
                <h5>{{ auctionData.name }}</h5>
              </router-link>
            </div>
          </div>
          <div class="card-body card-default">
            <div class="data-container">
              <div class="data">
                <p class="pm-0"><span class="text-secondary">الصنع: </span><span>{{ auctionData.year }}</span></p>
                <p class="pm-0"><span class="text-secondary">السعر: </span><span>{{ currentPrice }} دينار</span></p>
                <p class="pm-0"><span class="text-secondary">ينتهي: </span><span v-html="formattedFinishData"></span>
                </p>
              </div>
              <div class="data timer-container">
                <section>
                  <label class="text-secondary mb-0">الوقت: </label>
                  <Timer :timer-seconds="auctionData.seconds" />
                </section>
              </div>
            </div>
          </div>
          <div class="card-footer card-default"
            :class="{ 'border-top': (adminType && canUpdateAuction) || (userType && !dateFinished) }">
            <div class="buttons" v-if="adminType && canUpdateAuction">
              <div v-if="deleting">
                <modal-btn :target="`#${restoreId}`">إسترجاع</modal-btn>
              </div>
              <div v-else>
                <router-link :to="editLink" class="btn btn-info">تعديل</router-link>
                <modal-btn v-if="hasInvoice" :target="`#${checkInvoiceId}`">تدقيق</modal-btn>
              </div>
            </div>
            <div class="buttons" v-else-if="userType && !dateFinished">
              <router-link :to="bidLink" class="btn btn-red">مزاودة</router-link>
              <button class="btn btn-dark" @click="follow">{{ following }}</button>
            </div>
          </div>
        </div>
      </div>
      <teleport to="#app" v-if="adminType && canUpdateAuction">
        <confirm-model v-if="hasInvoice" :target-id="checkInvoiceId"
          :title="invoiceChecked ? 'عرض الفاتورة' : 'تدقيق الفاتورة'" header-bg="bg-info"
          :confirm-btn="invoiceChecked ? 'hide' : 'btn-info'" :cancel-btn="invoiceChecked ? 'btn-info' : 'btn-danger'"
          :cancel-text="invoiceChecked ? 'تم' : 'إلغاء'" confirm-text="تدقيق" @confirmed="checkInvoice">
          <p class="pm-0" v-if="!invoiceChecked">هل أنت متأكد من تدقيق الفاتورة ؟</p>
          <p class="pm-0">
            <span>عرض الفاتورة ؟</span>
            <download-invoice :invoice-id="auctionData.invoice"></download-invoice>
          </p>
        </confirm-model>
        <confirm-model v-if="deleting" :target-id="restoreId" title="إسترجاع المزاد" confirm-text="إسترجاع"
          @confirmed="restore">
          <p class="pm-0">
            <span>هل أنت متأكد من إسترجاع المزاد</span>&nbsp;
            <span class="link">{{ auctionData.name }}</span>&nbsp;
            <span>؟</span>
          </p>
        </confirm-model>
      </teleport>
    </div>
  </div>
</template>

<script>
import Timer from "@/components/UI/Timer"
import ModalBtn from "@/components/UI/ModalBtn"
import Storage from "@/common/Storage"
import date from "@/common/dateMethods"
import ConfirmModel from "@/components/UI/ConfirmModel"
import DownloadInvoice from "@/components/Invoices/DownloadInvoice"
import types from "@/common/types"
import random from "@/common/random"

export default {
  components: { DownloadInvoice, ConfirmModel, ModalBtn, Timer },
  props: {
    auction: { required: true }
  },
  emits: ['invoice-checked', 'restored'],
  data() {
    return {
      auctionData: {},
      following: '',
      invoice_checked: false,
    };
  },
  computed: {
    adminType() {
      return this.$route.name.startsWith('admin.auctions') || this.$route.name.startsWith('user.auctions');
    },
    userType() {
      return this.$route.name.startsWith('auctions.index') || this.$route.name.startsWith('client.auctions');
    },
    canUpdateAuction() {
      return this.$store.getters.canUpdateAuction;
    },
    deleting() {
      return this.$route.name === 'admin.auctions.deleted' && !!this.auctionData.deleted
    },
    editLink() {
      if (!this.auctionData || !this.canUpdateAuction || this.deleting) {
        return '#'
      }

      return { name: this.dateFinished ? 'auctions.status' : 'auctions.edit', params: { auction: this.auctionData.slug } }
    },
    bidLink() {
      if (!this.auctionData || this.dateFinished) {
        return '#'
      }

      return { name: 'auctions.bid', params: { auction: this.auctionData.slug } }
    },
    authorized() {
      return this.$store.getters.authorized;
    },
    auctionAltText() {
      return `مزاد ${!!this.auctionData ? this.auctionData.name : ''}`;
    },
    border() {
      if (this.userType)
        return 'red-colored-border';
      return 'multi-colored-border';
    },
    imagePath() {
      if (!this.auctionData || !this.auctionData.image)
        return '#';
      return `${this.$store.getters.publicPath}/storage/${this.auctionData.image}`;
    },
    formattedFinishData() {
      return !!this.auctionData ? date.withTime(this.auctionData.finish_date) : '';
    },
    currentPrice() {
      if (!this.auctionData)
        return '';
      if (!this.auctionData.current_price)
        return this.auctionData.opening_price;

      return this.auctionData.current_price;
    },
    rowId() {
      return `auction-row-${this.auctionData.id}`;
    },
    hasInvoice() {
      return !!this.auctionData && !!this.auctionData.invoice && !this.invoiceChecked;
    },
    checkInvoiceId() {
      return !!this.auctionData ? `check-invoice-${this.auctionData.id}` : '';
    },
    invoiceChecked() {
      return !!this.auctionData && types.checkY(this.auctionData.invoice_checked);
    },
    dateFinished() {
      return !this.auctionData || this.auctionData.seconds <= 0
    },
    restoreId() {
      return `restore-auction-${this.auctionData.id || random.string(10)}`
    }
  },
  methods: {
    redirect() {
      this.$store.dispatch('setPosition', this.rowId)
    },
    setAuction(auction) {
      if (!!auction) {
        this.auctionData = auction
      }
    },
    async follow() {
      if (!this.authorized || !this.auctionData)
        return http.popupMessage('error', 'يرجى تسجيل الدخول للمتابعة');
      if (this.$store.getters.isAdmin)
        return http.popupMessage('error', 'المتابعة فقط للمستخدمين');
      const response = await http.send('follow', { auction: this.auctionData.id });
      if (http.responseAccepted(response)) {
        let following = response.data.following;
        this.following = following ? 'إلغاء المتابعة' : 'متابعة';
        http.popupMessage('success', following ? 'تم الإضافة الى قائمة المتابعة' : 'تم الإزالة من قائمة المتابعة');
        this.clearStorage();
      }
    },
    setFollowing() {
      if (!this.auctionData || !this.auctionData.following)
        return this.following = 'متابعة';
      return this.following = this.auctionData.following > 0 ? 'إلغاء المتابعة' : 'متابعة';
    },
    async checkInvoice() {
      if (!this.auctionData || !this.auctionData.invoice)
        return;
      const response = await http.send('invoices/check', { invoice: this.auctionData.invoice });
      if (http.responseAccepted(response, 'invoice_checked')) {
        http.popupMessage('success', 'تم تأكيد الفاتورة')
        this.clearStorage();
        this.hideModal(`#${this.checkInvoiceId}`);
        this.auctionData.invoice_checked = response.data.invoice_checked;
        this.$emit('invoice-checked');
      }
    },
    async restore() {
      if (!!this.auctionData && !!this.auctionData.slug) {
        const response = await http.send('restore-auction', { auction: this.auctionData.slug })
        if (http.responseAccepted(response)) {
          this.clearStorage()
          this.$emit('restored', this.auctionData.id)
          this.hideModal(`#${this.restoreId}`);
          http.successMessage("تم إسترجاع المزاد" + " " + this.auctionData.name + " بنجاح!")
        }
      }
    },
    clearStorage() {
      Storage.remove('admin-counters');
      Storage.removeStartWith('auction-categories');
    },
  },
  created() {
    this.setAuction(this.auction);
    this.setFollowing();
    if (!!this.auctionData) {
      const channel = `auction.${this.auctionData.id}`;
      Echo.leave(channel);
      Echo.channel(channel)
        .listen('NewBidEvent', (e) => {
          if (!!e.auction) {
            let auction;
            try {
              auction = JSON.parse(e.auction);
            } catch (e) {
              auction = e.auction;
            }
            if (!!auction) {
              if (!!auction.current_price)
                this.auctionData.current_price = auction.current_price;

              if (!!auction.year)
                this.auctionData.year = auction.year;

              if (!!auction.finish_date)
                this.auctionData.finish_date = auction.finish_date;
                
              if (!!auction.seconds)
                this.auctionData.seconds = auction.seconds;
            }
          }
        });
    }
  }
}
</script>

<style scoped>
.card-default {
  background-color: transparent !important;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.border-top {
  border-color: #b1bbc4 !important;
}

.buttons {
  padding: 0 2px;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.buttons .btn {
  border-radius: 0 !important;
  border: none !important;
  margin: 0.25rem !important;
}

.buttons .btn:last-child {
  margin-left: 0 !important;
}

.data-container {
  display: flex;
  justify-content: space-between;
}

.data-container .data {
  width: 50%;
}

.timer-container {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .info {
    padding: 0.5rem !important;
  }
}

@media screen and (max-width: 400px) {
  .data-container {
    flex-direction: column !important;
  }

  .data-container .data {
    width: 100%;
  }

  .buttons .btn {
    display: block !important;
    width: 100%;
    margin: 0.25rem 0 !important;
    font-size: 14px;
  }
}

@media screen and (min-width: 1200px) {
  img {
    max-height: 170px;
  }
}

@media screen and (max-width: 768px) {
  img {
    max-height: 250px;
  }
}
</style>
