class filesController {
  constructor() {
  }

  isEmpty(id) {
    const input = document.getElementById(id);
    return !input || !input.files || input.files.length === 0;
  }

  isFile(selector) {
    if (!selector) return false;
    const element = $_(selector);
    return !!element && element.is('input[type=file]');
  }
}

const files = new filesController();
export default files;