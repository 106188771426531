<template>
  <label @click="downloadInvoice" :class="triggerClass" :title="title"><slot>عرض</slot></label>
  <form v-if="!!invoiceId && !!token" :action="streamInvoice" method="get" hidden target="_blank">
    <input type="hidden" name="q" :value="invoiceId">
    <input type="hidden" name="token" :value="token">
    <button type="submit" :id="streamId"></button>
  </form>
</template>

<script>
export default {
  props: {
    invoiceId: {required: true},
    triggerClass: {default: 'link'},
    title: {}
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    streamInvoice() {
      return !this.invoiceId ? '#' : `${this.$store.getters.publicPath}/api/invoice/stream`;
    },
    streamId() {
      return !this.invoiceId ? '' : `stream${this.invoiceId}`;
    },
  },
  methods: {
    downloadInvoice() {
      this.clickById(this.streamId)
    }
  }
}
</script>
