<template>
  <div>
    <div class="timer-box" v-if="!expired" :class="mainBoxClass">
      <div class="t-box t-s" :class="tBoxClass">
        <span :class="secondsText">{{ seconds }}</span>
        <span>ثانية</span>
      </div>
      <div class="t-box t-m" :class="tBoxClass">
        <span>{{ minutes }}</span>
        <span>دقيقة</span>
      </div>
      <div class="t-box t-h" :class="tBoxClass">
        <span>{{ hours }}</span>
        <span>&nbsp;ساعة</span>
      </div>
      <div class="t-box t-d" :class="tBoxClass">
        <span>{{ days }}</span>
        <span>&nbsp;يوم&nbsp;</span>
      </div>
    </div>
    <label v-else><strong>منتهي</strong></label>
  </div>
</template>

<script>
import Storage from "@/common/Storage";

export default {
  emits: ["timer-finished"],
  props: {
    timerSeconds: {required: true},
    storageIntervalId: {},
    mainBoxClass: {},
    tBoxClass: {},
  },
  data() {
    return {
      interval: "",
      expired: false,
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      secondsText: "text-light",
    };
  },
  watch: {
    async timerSeconds() {
      await this.startTimer();
    },
    seconds() {
      if (this.minutes <= 0 && this.hours <= 0 && this.days <= 0 && this.seconds > 0 && this.seconds <= 30)
        return this.secondsText = this.seconds % 2 === 0 ? "text-danger" : "text-light";

      return this.secondsText = "text-light";
    },
  },
  methods: {
    expireTimer() {
      this.reset(true);
    },
    reset(expired = false) {
      this.clearTimerInterval();
      this.expired = expired;
    },
    clearTimerInterval() {
      clearInterval(this.interval);

      if (this.storageIntervalId) {
        const stored_interval = Storage.get(this.storageIntervalId);
        if (stored_interval) {
          clearInterval(stored_interval.data);
          Storage.remove(this.storageIntervalId);
        }
      }
    },
    async startTimer() {
      await this.reset();
      let seconds = this.timerSeconds

      if (seconds <= 0) {
        return this.expireTimer();
      }

      this.interval = setInterval(() => {
        this.days = Math.floor(seconds / (60 * 60 * 24));
        this.hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
        this.minutes = Math.floor((seconds % (60 * 60)) / 60);
        this.seconds = Math.floor(seconds % 60);

        // If the count-down is over, write some text
        if (seconds <= 0) {
          this.$emit("timer-finished");
          this.expireTimer();
        }

        // Decrease current date
        seconds--
      }, 1000);

      if (!!this.storageIntervalId) {
        Storage.set(this.storageIntervalId, this.interval, -1);
      }
    },
  },
  created() {
    this.startTimer();
  }
};
</script>
