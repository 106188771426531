import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const auth = {
  state() {
    return {
      authorized: "",
    };
  },
  getters,
  actions,
  mutations,
};

export default auth;
