<template>
  <div id="pre_loader" class="h-100 w-100 position-fixed pm-0">
    <div class="spinner-container">
      <div v-if="!shouldReloadMsg" class="spinner-border text-danger">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else>
        <h5 class="spinner-text">{{ shouldReloadMsg }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    shouldReloadMsg() {
      return this.$store.getters.shouldReloadMsg;
    },
  },
};
</script>

<style scoped>
#pre_loader {
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-border {
  width: 4rem;
  height: 4rem;
  border: 0.6em solid #dc3545;
  border-right-color: transparent;
}
.spinner-text {
  color: #d1d1d1;
}

.spinner-border,
.spinner-grow {
  -webkit-animation-duration: 0.6s !important;
  animation-duration: 0.6s !important;
}
</style>
