<template>
  <small class="form-error font-weight-bolder pm-0" :id="`${id}_error`"></small>
</template>

<script>
export default {
  props: {
    id: { required: true },
  },
};
</script>