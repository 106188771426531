<template>
  <div v-if="!!partner" class="card h-100 border-0 bg-transparent">
    <div
      class="card-body bg-white d-flex flex-column justify-content-center p-1"
    >
      <img
        class="w-100 partner-image"
        v-if="!!image"
        :src="image"
        :alt="partner.name"
      />
    </div>
    <div class="card-footer bg-transparent border-0 partner-name">
      <p class="text-center">{{ partner.name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: { partner: { required: true }, localPath: { default: false } },
  computed: {
    publicPath() {
      return this.$store.getters.publicPath;
    },
    image() {
      return !this.partner || !this.partner.path || !this.publicPath
        ? ""
        : `${this.publicPath}/storage/${this.partner.path}`;
    },
  },
};
</script>

<style scoped>
.partner-image {
  max-height: 20vh;
}

@media (max-width: 1024px) {
  .partner-name {
    display: none;
  }
}
</style>
