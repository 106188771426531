<template>
  <center-content>
    <form class="m-auto" @submit.prevent="login">
      <base-card>
        <template #header>تسجيل الدخول</template>
        <template #body>
          <div class="row justify-content-center mb-2">
            <div class="col-sm-10">
              <label>الإيميل او إسم المستخدم</label>
              <form-input
                id="username"
                placeholder="الإيميل او إسم المستخدم"
                autofocus
              ></form-input>
            </div>
          </div>
          <div class="row justify-content-center mb-2">
            <div class="col-sm-10">
              <label>كلمة المرور</label>
              <form-input
                id="password"
                type="password"
                placeholder="كلمة المرور"
                autofocus
              ></form-input>
            </div>
          </div>
          <div class="row justify-content-center mb-2">
            <div class="col-sm-10">
              <button
                type="submit"
                id="login"
                class="btn btn-dark nr-block mb-1"
              >
                سجل الدخول
              </button>
              <p class="text-left m-0 mb-1">
                <span>نسيت كلمة المرور؟</span>&nbsp;
                <router-link
                  :to="{ name: 'forget.password' }"
                  class="link danger"
                  >إستعادة</router-link
                >
              </p>
              <p class="text-left m-0">
                <span>ليس لديك حساب؟</span>&nbsp;
                <router-link to="/register" class="link danger"
                  >تسجيل حساب جديد</router-link
                >
              </p>
            </div>
          </div>
        </template>
      </base-card>
    </form>
  </center-content>
</template>

<script>
import BaseCard from "../../UI/BaseCard";
import FormInput from "../../Forms/FormInput";
import CenterContent from "@/components/UI/CenterContent";

export default {
  components: { CenterContent, FormInput, BaseCard },
  methods: {
    async login(e) {
      if (!$_("#username").val() || !$_("#password").val())
        return http.popupMessage("error", messages.checkFields);

      const login = $_("#login");
      login.prop("disabled", true);
      await this.$store.dispatch("login", new FormData(e.target));
      login.prop("disabled", false);
    },
  },
};
</script>
