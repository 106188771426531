<template>
  <div class="row">
    <div class="col-md-3 col-sm-12 button-fixed">
      <div
        class="p-3 pb-4 bg-custom red-colored-border border-bottom-0 text-danger "
      >
        <div class="row">
          <div class="col-10 text-center">
            <h4>تحديث البيانات</h4>
          </div>
        </div>
        <p>
          يرجى التواصل مع الادارة لتحديث بياناتك
        </p>
        <router-link
          :to="{ name: 'should.update.data' }"
          class="btn btn-danger w-100"
          >تحديث بياناتي</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpdateMyData",
};
</script>

<style scoped>
.bg-custom {
  background-color: #fff;
}

.button-fixed {
  bottom: 0;
  position: fixed;
  right: 0;
  border-radius: 4px;
  text-align: center !important;
}

p {
  font-size: 14px;
}
</style>
