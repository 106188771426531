<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="!!width ? width : '50px'"
    :height="!!height ? height : '50px'"
    viewBox="0 0 512 512"
  >
    <g>
      <circle style="fill:#1FCFC1;" cx="256.551" cy="470.479" r="31.323" />
      <path
        style="fill:#1FCFC1;"
        d="M256,401.786c-17.34,0-31.395-14.057-31.395-31.395v-97.04c0-17.34,14.057-31.395,31.395-31.395
		c46.584,0,84.482-37.898,84.482-84.482S302.584,72.991,256,72.991s-84.482,37.898-84.482,84.482
		c0,17.34-14.057,31.395-31.395,31.395s-31.395-14.057-31.395-31.395C108.727,76.266,174.793,10.199,256,10.199
		s147.273,66.066,147.273,147.273c0,70.437-49.702,129.482-115.878,143.906v69.013C287.395,387.731,273.34,401.786,256,401.786z"
      />
    </g>
    <g>
      <path
        style="fill:#4D4D4D;"
        d="M256.55,512c-22.895,0-41.522-18.627-41.522-41.522s18.627-41.522,41.522-41.522
		s41.522,18.627,41.522,41.522S279.445,512,256.55,512z M256.55,449.353c-11.647,0-21.124,9.476-21.124,21.124
		c0,11.647,9.476,21.125,21.124,21.125s21.124-9.476,21.124-21.124C277.673,458.831,268.197,449.353,256.55,449.353z"
      />
      <path
        style="fill:#4D4D4D;"
        d="M256,411.986c-22.935,0-41.594-18.659-41.594-41.594V273.35c0-22.935,18.659-41.594,41.594-41.594
		c40.96,0,74.284-33.323,74.284-74.284S296.96,83.189,256,83.189s-74.284,33.323-74.284,74.284
		c0,22.935-18.659,41.594-41.594,41.594s-41.594-18.659-41.594-41.594C98.527,70.642,169.169,0,256,0
		s157.473,70.642,157.473,157.473c0,36.106-12.579,71.41-35.42,99.404c-20.756,25.44-49.086,43.867-80.458,52.489v61.025
		C297.594,393.327,278.935,411.986,256,411.986z M256,62.79c52.208,0,94.682,42.475,94.682,94.682S308.208,252.154,256,252.154
		c-11.687,0-21.196,9.509-21.196,21.196v97.041c0,11.687,9.509,21.196,21.196,21.196s21.196-9.509,21.196-21.196v-69.014
		c0-4.796,3.341-8.944,8.028-9.966c62.493-13.62,107.85-69.95,107.85-133.94c0-75.582-61.491-137.073-137.074-137.073
		S118.926,81.889,118.926,157.473c0,11.687,9.509,21.196,21.196,21.196s21.196-9.509,21.196-21.196
		C161.318,105.265,203.792,62.79,256,62.79z"
      />
      <path
        style="fill:#4D4D4D;"
        d="M256,379.41c-5.633,0-10.199-4.566-10.199-10.199v-59.155c0-5.633,4.566-10.199,10.199-10.199
		c5.633,0,10.199,4.566,10.199,10.199v59.155C266.199,374.844,261.633,379.41,256,379.41z"
      />
      <path
        style="fill:#4D4D4D;"
        d="M256,292.717c-5.633,0-10.199-4.566-10.199-10.199v-9.179c0-5.633,4.566-10.199,10.199-10.199
		c5.633,0,10.199,4.566,10.199,10.199v9.179C266.199,288.151,261.633,292.717,256,292.717z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: ["width", "height"],
};
</script>
