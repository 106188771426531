import Storage from "@/common/Storage";
import store from "@/store";
import types from "@/common/types";

const actions = {
  setShouldReloadAction(context, status) {
    context.commit("setShouldReload", status);
  },
  async setNotesAction(context) {
    let notes = Storage.get("notes-list");
    notes = !!notes ? notes.data : "";
    if (!notes) {
      const response = await http.send("notes-list", { _method: "GET" });
      if (http.responseAccepted(response)) notes = response.data.items;
      if (!!notes && Array.isArray(notes) && notes.length > 0)
        notes = notes.map((note) => note.content);
    }
    if (!!notes) {
      Storage.set("notes-list", notes, 10);
      context.commit("setNotes", notes);
    }
  },
  async setCitiesAction(context) {
    let cities = Storage.get("cities");
    if (!!cities) {
      context.commit("setCities", cities.data);
    } else {
      const response = await http.send("cities", { _method: "GET" });
      if (http.responseAccepted(response)) {
        cities = response.data.cities;
        context.commit("setCities", cities);
        Storage.set("cities", cities, 10); // commented
      }
    }
  },
  async setColorsAction(context) {
    let colors = Storage.get("colors");
    if (!!colors) {
      context.commit("setColors", colors.data);
    } else {
      const response = await http.send("colors-list", { _method: "GET" });
      if (http.responseAccepted(response)) {
        colors = response.data.colors;
        context.commit("setColors", colors);
        Storage.set("colors", colors, 10); // commented
      }
    }
  },
  async setAreasAction(context, payload) {
    const response = await http.send("areas", {
      _method: "GET",
      city: payload.city,
    });
    if (http.responseAccepted(response)) {
      const areas = response.data.areas;
      context.commit("setAreas", areas);
    }
  },
  setUserActivationAction(context, payload) {
    context.commit("setUserActivation", payload);
  },
  async setInsurancesAction(context, payload) {
    let insurances = payload;
    if (!insurances) {
      insurances = Storage.get("insurances-list");
      insurances = !!insurances ? insurances.data : "";
      if (!insurances) {
        const response = await http.send("insurances-menu", { _method: "GET" });
        if (http.responseAccepted(response)) {
          insurances = response.data.insurances;
        }
      }
    }
    if (!!insurances) {
      insurances = types.objectToArray(insurances);
      if (!insurances.find((insurance) => parseInt(insurance.id) === -1))
        insurances.push({ id: -1, content: "إلغاء التأمين" });
      Storage.set("insurances-list", insurances, 60); // commented
      context.commit("setInsurances", insurances);
    }
  },
  async setCountersAction(context) {
    let counters = Storage.get("admin-counters");
    if (!!counters) {
      counters = counters.data;
    } else {
      const response = await http.send("admin-counters", { _method: "GET" });
      if (http.responseAccepted(response)) {
        counters = response.data;
        Storage.set("admin-counters", counters, 60); // commented
      }
    }

    if (!!counters) {
      context.commit("setCounters", counters);
      if (!!counters.userActivation)
        await store.dispatch(
          "setUserActivationAction",
          counters.userActivation
        );
    }
  },
  async setPartnersAction(context, payload) {
    Storage.remove("index-partners")
    let partners;
    if (!!payload) {
      partners = payload;
    } else {
      const response = await http.send("partners", { _method: "GET" });
      if (http.responseAccepted(response)) {
        partners = response.data;
      }
    }

    if (!!partners) {
      // Storage.set("index-partners", partners, 60);
      context.commit("setPartners", partners);
    }
  },
  async connectToPublicChannels() {
    await Echo.leave("partners.changed");
    await Echo.channel("partners.changed").listen(
      "PartnersEvent",
      async function(e) {
        if (!!e && !!e.partners)
          await store.dispatch("setPartnersAction", JSON.parse(e.partners));
      }
    );
  },
  setIntendedUrl(context, intendedRoute) {
    context.commit("setIntendedUrl", intendedRoute);
  },
  setPosition(context, position) {
    context.commit("setPosition", position);
  },
};

export default actions;
