export default {
  data() {
    return {
      nationality: "أردني",
    };
  },
  computed: {
    jordanian() {
      return this.nationality === "أردني";
    },
    nationalNumDesc() {
      return this.jordanian ? "الرقم الوطني" : "جواز السفر";
    },
    nationalityType() {
      return this.jordanian ? "number" : "text";
    },
  },
  methods: {
    setNationality(nationality) {
      this.nationality = nationality;
    },
    async setAreas(city) {
      await this.$store.dispatch("setAreasAction", { city });
    },
  },
  async created() {
    await this.$store.dispatch("setCitiesAction");
  },
};
