import Echo from "laravel-echo";
import store from "@/store";

window.baseURL = `${store.getters.publicPath}/api/`;
window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  wsHost: store.getters.websockets,
  forceTLS: process.env.NODE_ENV === "production",
  wsPort: process.env.VUE_APP_PUSHER_PORT ?? 6001,
  authEndpoint: baseURL + "broadcasting/auth",
});
store.dispatch("connectToPublicChannels");
