<template>
  <button
    :type="btnType"
    :disabled="disabled"
    class="btn rounded-0"
    :class="classes"
    data-toggle="modal"
    :data-target="target"
    :title="title"
    @click="click"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  emits: ["click"],
  props: {
    disabled: Boolean,
    btnClass: String,
    title: String,
    target: {
      required: true,
      type: String,
    },
    btnType: { default: "button" },
  },
  methods: {
    click(e) {
      return this.$emit("click", e);
    },
  },
  computed: {
    classes() {
      return !!this.btnClass ? this.btnClass : "btn-success";
    },
  },
};
</script>

<style scoped>
.button.btn-sm {
  font-size: 0.9rem;
}
</style>
