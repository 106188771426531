<template>
  <div class="row">
    <div class="col-md-6">
      <label>
        <span>الهاتف</span>&nbsp;<small class="text-danger font-weight-bolder"
          >(إجباري)</small
        >
      </label>
      <form-input id="mobile1" placeholder="الهاتف (إجباري)"></form-input>
    </div>
    <div class="col-md-6">
      <div v-if="!addSecondMobile">
        <label>إضافة رقم هاتف إحتياطي</label>
        <br />
        <button class="link" @click="setSecondMobile">
          <span>أضف</span>&nbsp;<i class="fa fa-plus"></i>
        </button>
      </div>
      <div v-else>
        <label class="link mr-1" @click="setSecondMobile">إزالة</label>
        <label>
          <span>الهاتف</span>&nbsp;<small class="text-danger font-weight-bolder"
            >(إختياري)</small
          >
        </label>
        <form-input id="mobile2" placeholder="الهاتف (إختياري)"></form-input>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from "@/components/Forms/FormInput";
export default {
  components: { FormInput },
  data() {
    return {
      addSecondMobile: false,
    };
  },
  props: {
    container: { default: "row" },
    inputs: { default: "col-md-6" },
  },
  methods: {
    setSecondMobile() {
      this.addSecondMobile = !this.addSecondMobile;
    },
  },
};
</script>
