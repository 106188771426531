<template>
  <div class="pm-0">
    <label :class="label" class="d-block text-left" v-if="!!text && showLabel" :for="id" :id="labelId">
      <abbr v-if="!!abbr" :title="abbr">{{ text }}</abbr>
      <span v-else>{{ text }}</span>
    </label>
    <input v-if="!slotPassed"
           :type="type" :id="id" :name="inputName" :title="title" :value="value"
           :placeholder="placeholder || text" :class="classes" :min="min" :max="max"
           :autocomplete="autocompleteDefault" @change="valueChanged" :accept="accept"
           :multiple="multiple" :hidden="hidden" :disabled="disabled" :maxlength="maxLength" :dir="dir">
    <slot v-else></slot>
    <ErrorLabel v-if="error" :id="id" />
  </div>
</template>

<script>
import ErrorLabel from "@/components/UI/ErrorLabel";

export default {
  components: {ErrorLabel},
  props: {
    id: {required: true},
    name: {type: [String, Number]},
    type: {default: 'text'},
    text: {type: [String, Number]},
    abbr: {type: [String, Number]},
    title: {type: [String, Number]},
    placeholder: {type: [String, Number]},
    value: {},
    inputClass: {},
    accept: {},
    slotPassed: {},
    model: {},
    max: {},
    maxLength: {
      default: 255
    },
    min: {default: 0},
    autocomplete: {default: true, type: Boolean},
    disabled: {default: false, type: Boolean},
    multiple: {default: false, type: Boolean},
    error: {default: true, type: Boolean},
    showLabel: {default: true, type: Boolean},
    hidden: {default: false, type: Boolean},
    label: {},
    dir: {
      default: 'rtl',
      type: String
    },
  },
  emits: ['changed'],
  computed: {
    autocompleteDefault() {
      if (this.type === 'password')
        return 'off';

      return this.autocomplete ? 'on' : 'off';
    },
    inputName() {
      return !!this.name ? this.name : this.id;
    },
    classes() {
      return 'form-control rounded-0' + ' ' + this.inputClass;
    },
    labelId() {
      return !!this.id ? `label_${this.id}` : '';
    }
  },
  methods: {
    valueChanged(e) {
      if (!!e)
        return this.$emit('changed', e);
    }
  }
}
</script>
