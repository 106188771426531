import dateMethods from "@/common/dateMethods";

export default {
  methods: {
    emptyField(selector) {
      if (!!selector) {
        if ($_(selector).hasClass("selectpicker")) {
          $_(selector).selectpicker("val", "");
          this.refreshSelectPicker(selector);
        } else {
          $_(selector).val("");
          $_(selector).text("");
        }
      }
    },
    clickById(id) {
      if (!!id) $_(`#${id}`).click();
    },
    hideModal(modalSelector) {
      if (!!modalSelector) $_(modalSelector).modal("hide");
    },
    showModal(modalSelector) {
      if (!!modalSelector) $_(modalSelector).modal("show");
    },
    hideItemById(item) {
      if (!item) {
        item = $_(`#${item}`);
        item.addClass("animated zoomOutRight");
        setTimeout(function() {
          item.remove();
        }, 200);
      }
    },
    elementExists(selector) {
      return !selector ? false : $_(selector).length;
    },
    equalIntegers(integer1, integer2) {
      return parseInt(integer1) === parseInt(integer2);
    },
    refreshSelectPicker(selector) {
      const selectpicker = $_(selector);
      try {
        selectpicker.selectpicker("refresh");
      } catch {
        console.error(
          `could not refresh selectpicker with selector: ${selector}`
        );
      }
    },
    formatDate(date, withTime = true) {
      if (!date) {
        return ''
      }

      return withTime ? dateMethods.withTime(date) : dateMethods.withoutTime(date)
   }
  },
  mounted() {
    $_('[data-toggle="tooltip"]').tooltip();
  },
};
