const rules = {
  auctions: {
    manufacturer: { required: true },
    model: { required: true },
    year: { required: true },
    company_id: { required: true },
    plate_no: { required: true },
    claim_no: { required: true },
    auto_bid: { required: true },
    transferred: { required: true },
    min_price: { required: true },
    lowest_bid: { required: true },
    finish_date: { required: true },
    extra_minutes: { required: true },
    opening_price: { required: true },
    main_photo: { required: true },
    receipt_form: { required: true },
    auth_form: { required: true },
    city_id: { required: true },
    area_id: { required: true },
    licensed: { required: true },
    start_date: { required: true },
  },
};

export default rules;
