<template>
  <main id="main" class="site-main">
    <div data-elementor-type="wp-page" data-elementor-id="16" class="elementor elementor-16">
      <section
        class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-e9440a0 elementor-section-full_width elementor-section-height-default elementor-section-height-default">
        <div class="elementor-background-overlay"></div>
        <div class="elementor-shape elementor-shape-bottom" data-negative="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path class="elementor-shape-fill"
              d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
          </svg>
        </div>
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-12bd7cd"
            data-id="12bd7cd" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-4f1b96d elementor-widget elementor-widget-heading"
                data-id="4f1b96d" data-element_type="widget" data-widget_type="heading.default">
                <div class="elementor-widget-container">
                  <h2 class="elementor-heading-title elementor-size-default font-weight-bold" style="font-size: 30px">
                    أفضل منصة لمزادات السيارات
                    <br />
                    إشتري أو بيع سيارتك عبر الإنترنت
                  </h2>
                </div>
              </div>
              <section
                class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-e831fda elementor-section-height-min-height elementor-section-boxed elementor-section-height-default">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-44538de ae-link-yes">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div class="elementor-background-overlay"></div>
                      <div class="elementor-element elementor-element-b1716d2 elementor-widget elementor-widget-heading"
                        data-id="b1716d2" data-element_type="widget" data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">
                            <router-link :to="{ name: 'guides' }" class="text-light font-weight-bold">
                              شراء المركبات
                            </router-link>
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-fe64627 elementor-widget elementor-widget-text-editor">
                        <div class="elementor-widget-container">
                          <p>
                            نحن نقود تجربة جديدة لسوق السيارات الأردني
                            بتكنولوجيا متطورة مدعومة بخبرة أحد عشر عاما" بمجال
                            المزادات الإلكترونية.
                            <br />
                            نحن نعلم أن سوق السيارات بطريقته التقليدية لا تواكب
                            متطلبات العصر الحديث و لذلك خدمات الشراء لدينا هي
                            طريقة حديثة و صناعة راسخة.
                          </p>
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-7c11d6d elementor-widget elementor-widget-image"
                        data-id="7c11d6d" data-element_type="widget" data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <router-link :to="{ name: 'auctions.index' }">
                            <img decoding="async" width="1024" height="576"
                              src="@/assets/jomazad/images/buy-car1024x576.png"
                              class="attachment-large size-large lazyautosizes lazyloaded" alt="" data-sizes="auto"
                              sizes="537px" srcset="
                                @/assets/jomazad/images/buy-car1024x576.png 1024w,
                                @/assets/jomazad/images/buy-car300x169.png   300w,
                                @/assets/jomazad/images/buy-car768x432.png   768w,
                                @/assets/jomazad/images/buy-car1536x864.png 1536w,
                                @/assets/jomazad/images/buy-car600x338.png   600w,
                                @/assets/jomazad/images/buy-car.png         1920w
                              " />
                          </router-link>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-2fb717a elementor-align-center elementor-widget elementor-widget-button"
                        data-id="2fb717a" data-element_type="widget" data-widget_type="button.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-button-wrapper">
                            <router-link :to="{ name: 'auctions.index' }"
                              class="elementor-button-link elementor-button elementor-size-sm">
                              <span class="elementor-button-content-wrapper">
                                <span class="elementor-button-text">
                                  عرض جميع المزادات
                                </span>
                              </span>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-wts-url="/sell-cars/" data-wts-link="yes" data-wts-new-window=""
                    class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-fe51fb2 ae-link-yes"
                    data-id="fe51fb2" data-element_type="column" data-settings='{"background_background":"classic"}'>
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div class="elementor-background-overlay"></div>
                      <div class="elementor-element elementor-element-60f2f8a elementor-widget elementor-widget-heading"
                        data-id="60f2f8a" data-element_type="widget" data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">
                            <router-link :to="{ name: 'sell.cars' }" class="text-light font-weight-bold">
                              بيع المركبات
                            </router-link>
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-552ce19 elementor-widget elementor-widget-text-editor"
                        data-id="552ce19" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <p>
                            أحصل على أفضل سعر لسيارتك يمكنك مراقبة حالة المزاد
                            بكل شفافية و في أي وقت
                            <br />
                            إستفد من قاعدة البيانات لدينا
                          </p>
                          <p>نحن أفضل منصة للبيع الفعال.</p>
                          <br />
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-89efb77 elementor-widget elementor-widget-image"
                        data-id="89efb77" data-element_type="widget" data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <router-link :to="{ name: 'sell.cars' }">
                            <img decoding="async" width="1024" height="576"
                              src="@/assets/jomazad/images/sell-car1024x576.png"
                              class="attachment-large size-large lazyautosizes lazyloaded" alt="" data-sizes="auto"
                              sizes="537px" srcset="
                                @/assets/jomazad/images/sell-car1024x576.png 1024w,
                                @/assets/jomazad/images/sell-car300x169.png   300w,
                                @/assets/jomazad/images/sell-car768x432.png   768w,
                                @/assets/jomazad/images/sell-car1536x864.png 1536w,
                                @/assets/jomazad/images/sell-car600x338.png   600w,
                                @/assets/jomazad/images/sell-car.png         1920w
                              " />
                          </router-link>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-06734f8 elementor-align-center elementor-widget elementor-widget-button"
                        data-id="06734f8" data-element_type="widget" data-widget_type="button.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-button-wrapper">
                            <router-link :to="{ name: 'add.car' }"
                              class="elementor-button-link elementor-button elementor-size-sm" role="button">
                              <span class="elementor-button-content-wrapper">
                                <span class="elementor-button-text">
                                  بيع سيارتك
                                </span>
                              </span>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <!-- <IndexAuctionSection :auctions="latestAuctions" /> -->
      <section class="m-0 py-5 bg-light">
        <div class="container">
          <div class="row my-3">
            <div class="col-12">
              <div class="title-subtile-holder">
                <h3 class="text-center text-uppercase font-weight-bold mb-0">يرجى إختيار الموقع الإلكتروني المطلوب</h3>
                <div class="svg-border">
                  <svg width="100%" height="25" viewBox="0 0 275 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="7" width="120" height="1" fill="#CCCCCC"></rect>
                    <rect x="155" y="7" width="120" height="1" fill="#CCCCCC"></rect>
                    <path
                      d="M144.443 14.6458C144.207 14.8818 143.897 15 143.588 15C143.278 15 142.968 14.8818 142.732 14.6454L137.874 9.78689C137.517 9.43023 137.43 8.90654 137.612 8.46798L136.617 7.47264L135.242 8.84723C135.517 9.2862 135.458 9.8809 135.066 10.2714C134.614 10.7245 133.888 10.7342 133.448 10.2936L130.324 7.17126C129.883 6.73028 129.893 6.00566 130.347 5.55298C130.738 5.16122 131.332 5.10231 131.771 5.37788L135.378 1.77014C135.102 1.33158 135.161 0.737682 135.553 0.346326C136.006 -0.10676 136.73 -0.116443 137.171 0.324136L140.295 3.44732C140.736 3.8879 140.726 4.61251 140.272 5.0656C139.88 5.45736 139.287 5.51586 138.849 5.2407L137.472 6.6169L138.59 7.73449C138.945 7.69334 139.314 7.80348 139.586 8.07622L144.444 12.9347C144.916 13.4071 144.916 14.1729 144.443 14.6458Z"
                      fill="#db3a32"></path>
                  </svg>
                </div>
                <div class="section-subtitle "></div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <a  href="https://joauctions.com" target="_blank"
                class="card border-4 border-dark bg-transparent text-decoration-none h-100">
                <img class="card-img-top index-image" src="@/assets/images/joauctions-cover-tr.png"
                  alt="Card image cap border-0" />
                <div class="card-body border-0 pt-0">
                  <h4 class="card-title text-dark text-center">
                    www.joauctions.com
                  </h4>
                  <h4 class="card-title text-dark text-center text-italic">
                    سيارات مضروبة و شركات
                  </h4>
                </div>
              </a>
            </div>

            <div class="col-md-6 mb-3">
              <router-link :to="{ name: 'auctions.index' }" class="card border-4 bg-black text-decoration-none h-100">
                <img class="card-img-top index-image object-fit-cover" src="@/assets/images/jomazad.jpeg"
                  alt="Card image cap border-0" />
                <div class="card-body border-0 pt-0">
                  <h4 class="card-title text-white text-center">www.jomazad.com</h4>
                  <h4 class="card-title text-white text-center text-italic">
                    <span>سيارات أفراد ومزادات</span>
                    <!-- <label
                  v-if="jomazad_auctions > 0"
                  class="ml-2 badge badge-light animated fadeIn infinite"
                >
                  {{ jomazad_auctions }} مزاد جديد
                </label> -->
                  </h4>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>

      <section
        class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-cc1e48a elementor-section-boxed elementor-section-height-default elementor-section-height-default border border-left-0 border-right-0 border-danger"
        data-id="cc1e48a" data-element_type="section" data-settings='{"background_background":"classic"}'>
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-58f8f69"
            data-id="58f8f69" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-bc4c192 elementor-widget elementor-widget-heading"
                data-id="bc4c192" data-element_type="widget" data-widget_type="heading.default">
                <div class="elementor-widget-container">
                  <h2 class="elementor-heading-title elementor-size-default">
                    بدك تبيع سيارة ؟
                  </h2>
                  <h2 class="elementor-heading-title elementor-size-default">
                    نستطيع المساعدة!
                  </h2>
                </div>
              </div>
              <div class="elementor-element elementor-element-daec7d1 elementor-widget elementor-widget-text-editor"
                data-id="daec7d1" data-element_type="widget" data-widget_type="text-editor.default">
                <div class="elementor-widget-container">
                  <p>
                    لقد ولت أيام قضاء أسابيع في بيع السيارة. بيع سيارتك معانا
                    بأفضل سعر و أسرع وقت.
                  </p>
                </div>
                <p>أدخل تفاصيل سيارتك عبر الإنترنت.</p>
              </div>
              <div
                class="elementor-element elementor-element-d91f657 elementor-align-right elementor-widget elementor-widget-button"
                data-id="d91f657" data-element_type="widget" data-widget_type="button.default">
                <div class="elementor-widget-container">
                  <div class="elementor-button-wrapper">
                    <router-link :to="{ name: 'add.car' }" class="btn btn-danger rounded-0" role="button">
                      بيع سيارتك
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-85afa21"
            data-id="85afa21" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-cf1e496 elementor-widget elementor-widget-image"
                data-id="cf1e496" data-element_type="widget" data-widget_type="image.default">
                <div class="elementor-widget-container">
                  <img decoding="async" width="1024" height="676"
                    style="-webkit-transform: scaleX(-1);transform: scaleX(-1);}"
                    src="@/assets/jomazad/images/mercedes-benz-g-2022-1024x676.jpg"
                    class="attachment-large size-large wp-post-image lazyautosizes ls-is-cached lazyloaded" alt=""
                    data-src="@/assets/jomazad/images/mercedes-benz-g-2022-1024x676.jpg"
                    data-srcset="@/assets/jomazad/images/mercedes-benz-g-2022-1024x676.jpg 1024w, @/assets/jomazad/images/mercedes-benz-g-2022-300x198.jpg 300w, @/assets/jomazad/images/mercedes-benz-g-2022-768x507.jpg 768w, @/assets/jomazad/images/mercedes-benz-g-2022-1536x1014.jpg 1536w, @/assets/jomazad/images/mercedes-benz-g-2022-600x396.jpg 600w, @/assets/jomazad/images/mercedes-benz-g-2022.jpg 1920w"
                    data-sizes="auto" sizes="550px" srcset="
                      @/assets/jomazad/images/mercedes-benz-g-2022-1024x676.jpg  1024w,
                      @/assets/jomazad/images/mercedes-benz-g-2022-300x198.jpg    300w,
                      @/assets/jomazad/images/mercedes-benz-g-2022-768x507.jpg    768w,
                      @/assets/jomazad/images/mercedes-benz-g-2022-1536x1014.jpg 1536w,
                      @/assets/jomazad/images/mercedes-benz-g-2022-600x396.jpg    600w,
                      @/assets/jomazad/images/mercedes-benz-g-2022.jpg           1920w
                    " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="mt-2" v-if="$store.getters.authorized">
        <PartnersData :background="false" />
      </section>
    </div>
  </main>
</template>

<script>
import PartnersData from "@/components/Users/PartnersData.vue";
// import IndexAuctionSection from "@/components/Auctions/IndexAuctionSection.vue";

export default {
  components: {
    PartnersData,
    // IndexAuctionSection,
  },
  data() {
    return {
      latestAuctions: [],
    };
  },
  methods: {
    async getLatestAuctions() {
      const response = await http.send("latest-auctions", { _method: "GET" });
      if (http.responseAccepted(response)) {
        this.latestAuctions = response.data;
      }
    },
  },
  async created() {
    await this.getLatestAuctions();
  },
};
</script>

<style>
@import "../../assets/jomazad/css/app.css";
</style>

<style scoped>
.index-image {
  height: 300px;
  width: 100%;
}

.index-redirect-image {
  height: 200px;
}

@media (max-width: 768px) {
  .index-redirect-image {
    height: 100px;
  }
}
</style>