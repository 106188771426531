import { createRouter, createWebHistory } from "vue-router";
import commonRoutes from "./routes/common";
import adminRoutes from "./routes/admin";
import store from "@/store";
import types from "@/common/types";

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes: [...adminRoutes, ...commonRoutes],
});

router.beforeEach(async function(to, from, next) {
  const authorized = store.getters.authorized;
  const isAdmin = store.getters.isAdmin;

  // refresh token
  if (types.isObject(authorized)) {
    let today = new Date();
    let expired_at = new Date(authorized.expired_at);
    let milliseconds = expired_at - today;
    let minutes = Math.round(milliseconds / 60000);

    if (minutes <= 15) {
      await store.dispatch("refreshToken");
    }
  }

  // if route is contact
  if (to.meta.requiresAuth && !authorized) {
    await store.dispatch("setIntendedUrl", to.fullPath);
    next({ name: "login" });
  } else if (to.meta.requiresUnAuth && !!authorized) {
    next({ name: "auctions.index" });
  } else if (store.getters.undefinedStatus) {
    await store.dispatch("logout");
    http.popupMessage("error", "حالة الحساب غير محددة");
  } else if (to.meta.superAdmin && !store.getters.superAdmin) {
    next({ name: "admin.dashboard" });
  } else if (to.meta.canActivateUser && !store.getters.canActivateUser) {
    next({ name: "admin.dashboard" });
  } else if (to.meta.canCreateUser && !store.getters.canCreateUser) {
    next({ name: "admin.dashboard" });
  } else if (to.meta.canCreateAuction && !store.getters.canCreateAuction) {
    next({ name: "admin.dashboard" });
  } else if (to.meta.canUpdateUser && !store.getters.canUpdateUser) {
    next({ name: "admin.dashboard" });
  } else if (to.meta.canUpdateAuction && !store.getters.canUpdateAuction) {
    next({ name: "admin.dashboard" });
  } else if (to.name === "contact") {
    next();
  } else if (store.getters.stumbled && to.name !== "user.stumbled") {
    next({ name: "user.stumbled" });
  } else if (
    store.getters.needMobileVerification &&
    to.name !== "should.verify.mobile"
  ) {
    next({ name: "should.verify.mobile" });
  } else if (to.meta.admin && !isAdmin) {
    // if normal user tried to access admin`s operations
    next({ name: "user.dashboard" });
  } else if (to.meta.user && isAdmin) {
    // if normal admin tried to access user`s operations
    next({ name: "admin.dashboard" });
  } else {
    next();
  }
});

router.afterEach(async function(to) {
  $_("#joAuctionNavbar").collapse("hide");
  document.title = !!to.meta.title ? to.meta.title : messages.joauctions;
  $_(".modal.show").modal("hide");
});

export default router;
