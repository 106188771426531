import Storage from "@/common/Storage";
import types from "@/common/types";
import store from "@/store";
import router from "@/router";
import UserRole from "@/extra/enums/UserRole";

const actions = {
  async login(context, data) {
    const response = await http.send("login", data);
    if (response.status === 422) {
      return;
    }

    await context.dispatch("setAuthorized", response.data);
    await router.push(store.getters.intendedRoute);

    // clear intended url
    await context.dispatch("setIntendedUrl", "");
  },
  async register(context, data) {
    const route = context.getters.isAdmin ? "create-user" : "register";
    const response = await http.send(route, data, {
      headers: { "Content-type": "multipart/form-data" },
    });

    if (http.responseAccepted(response, "user")) {
      Storage.removeStartWith("admin-counters");
      const registered = response.data.user;

      if (context.getters.isAdmin) {
        return router.replace({
          name: "users.edit",
          params: { user: registered.id },
        });
      }

      await context.dispatch("setAuthorized", registered);
      return router.replace("/");
    }
  },
  async setAuthorized(context, payload) {
    let authorized = payload;
    if (!types.isObject(authorized)) {
      authorized = Storage.get("authorized");

      if (types.isObject(authorized)) {
        authorized = authorized.data;
      }
    }

    if (!authorized) {
      await context.dispatch("clearAuthorized");
    } else {
      Storage.set("authorized", authorized, -1);
      await context.commit("setAuthorized", authorized);
      await context.dispatch("connectToUserChannels");
    }

    // if (!!authorized && !!authorized.id && process.env.NODE_ENV === 'production') {
    //   try {
    //     LogRocket.init('joauctions/joauctions')
    //     LogRocket.identify(authorized.id, {
    //       name: authorized.name,
    //       email: authorized.email,
    //     });
    //   } catch {
    //   }
    // }
  },
  async logout(context) {
    if (context.getters.authorized) {
      await http.send("logout");
    }

    await context.dispatch("clearAuthorized");
    await router.replace("/");
  },
  async clearAuthorized(context, payload) {
    await context.dispatch("leaveUserChannels");
    await context.commit("setAuthorized", null);

    Storage.remove("authorized");
    Storage.clear();

    if (!!payload && payload.redirect) {
      await router.replace("/");
    }
  },
  async connectToUserChannels(context) {
    await context.dispatch("leaveUserChannels");
    const authorized = context.getters.authorized;

    if (types.isObject(authorized)) {
      Echo.connector.pusher.config.auth.headers = {
        Authorization: "Bearer " + authorized.token,
        Accept: "application/json",
      };

      Echo.private(`user.auto.logout.${authorized.id}`)
        .listen("LogoutOtherDevices", async (e) => {
          if (
            parseInt(e.id) === parseInt(authorized.id) &&
            e.token !== authorized.token
          )
            await store.dispatch("logout");
        })
        .error(async (e) =>
          e?.status === 401 ? await context.dispatch("clearAuthorized") : null
        );

      if (context.getters.superAdmin) {
        Echo.private("new.activation.request")
          .listen("NewActivationRequest", async (e) => {
            Storage.removeStartWith("admin-counters");
            if (e.count > 0)
              await store.dispatch("setUserActivationAction", e.count);

            if (!!e.name)
              http.popupMessage(
                "info",
                `قام المستخدم ${e.name} برفع وصل تأمين`
              );
          })
          .error(async (e) =>
            e?.status === 401 ? await context.dispatch("clearAuthorized") : null
          );
      }

      if (authorized.role === UserRole.Client) {
        Echo.private(`account.status.${authorized.id}`).listen(
          "UserStatusEvent",
          async function(data) {
            if (!data) {
              return;
            }

            const user = data.user;
            if (!user) {
              return;
            }

            const status = parseInt(user?.status);
            let updated = false;
            if (!!user.add_insurance) {
              updated = true;
              authorized["add_insurance"] = user.add_insurance;
            }

            if (user?.insurance) {
              updated = true;
              authorized["insurance"] = user.insurance;
            }

            if (!!status && status !== parseInt(authorized["status"])) {
              updated = true;
              authorized["status"] = status;
              let routeName;
              switch (status) {
                case 1:
                  routeName = "user.stumbled";
                  break;
                case 2:
                  routeName = "should.verify.mobile";
                  break;
                case 3:
                  routeName = "should.update.data";
                  break;
                case 4:
                case 5:
                  if (
                    [
                      "user.stumbled",
                      "should.verify.mobile",
                      "should.update.data",
                    ].includes(context.getters.currentRoute)
                  )
                    routeName = "auctions.index";
                  break;
                default:
                  routeName = "";
              }
              if (!!routeName) await router.replace({ name: routeName });
            }
            if (updated) {
              await store.dispatch("setAuthorized", authorized);
              http.popupMessage("info", "تم تعديل حالة حسابك");
            }
          }
        );
      }
    }
  },
  async leaveUserChannels(context) {
    const authorized = context.getters.authorized;
    if (authorized) {
      Echo.leave(`user.auto.logout.${authorized.id}`);
      Echo.leave(`account.status.${authorized.id}`);
      if (context.getters.superAdmin) {
        Echo.leave(`new.activation.request`);
      }
    }
  },
  async refreshToken(context) {
    const authorized = context.getters.authorized;
    if (!authorized) {
      return;
    }

    const response = await http.send("refresh-token");

    if (http.responseAccepted(response)) {
      authorized["token"] = response.data.token;
      authorized["expired_at"] = response.data.expired_at;
      await context.dispatch("setAuthorized", authorized);
    }
  },
};

export default actions;
