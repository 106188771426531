import types from "@/common/types";
import UserRole from "@/extra/enums/UserRole";

const getters = {
  authorized(state) {
    return types.isObject(state.authorized) ? state.authorized : "";
  },
  stumbled(_, getters) {
    return !!getters.authorized && parseInt(getters.authorized.status) === 1;
  },
  needMobileVerification(_, getters) {
    return !!getters.authorized && parseInt(getters.authorized.status) === 2;
  },
  shouldUpdateData(_, getters) {
    return !!getters.authorized && parseInt(getters.authorized.status) === 3;
  },
  needActivation(_, getters) {
    return !!getters.authorized && parseInt(getters.authorized.status) === 4;
  },
  active(_, getters) {
    return (
      !!getters.authorized &&
      [3, 5].includes(parseInt(getters.authorized.status))
    );
  },
  undefinedStatus(_, getters) {
    return (
      !!getters.authorized &&
      ![1, 2, 3, 4, 5].includes(parseInt(getters.authorized.status))
    );
  },
  firstName(_, getters) {
    if (!getters.authorized || !getters.authorized.name) return "";
    let first = getters.authorized.name.split(" ");
    return !!first && first.length > 0 ? first[0] : "";
  },
  isAdmin(_, getters) {
    return (
      !!getters.authorized &&
      [UserRole.Admin, UserRole.Assistant].includes(getters.authorized.role)
    );
  },
  isAssistant(_, getters) {
    return (
      !!getters.authorized && getters.authorized.role === UserRole.Assistant
    );
  },
  isClient(_, getters) {
    return !!getters.authorized && getters.authorized.role === UserRole.Client;
  },
  superAdmin(_, getters) {
    return !!getters.authorized && getters.authorized.role === UserRole.Admin;
  },
  subAdmin(_, getters) {
    return (
      !!getters.authorized && getters.authorized.role === UserRole.Assistant
    );
  },
  permissions(_, getters) {
    return !!getters.authorized &&
      getters.authorized.role === UserRole.Assistant
      ? getters.authorized.permissions
      : "";
  },
  canActivateUser(_, getters) {
    if (getters.superAdmin) {
      return true;
    }

    return (
      getters.isAssistant &&
      getters.permissions &&
      types.checkY(getters.permissions.activate_user)
    );
  },
  canCreateUser(_, getters) {
    if (getters.superAdmin) {
      return true;
    }

    return (
      getters.isAssistant &&
      getters.permissions &&
      types.checkY(getters.permissions.create_user)
    );
  },
  canCreateAuction(_, getters) {
    if (getters.superAdmin) {
      return true;
    }

    return (
      getters.isAssistant &&
      getters.permissions &&
      (types.checkY(getters.permissions.approved_auctions) ||
        types.checkY(getters.permissions.not_approved_auctions))
    );
  },
  canUpdateUser(_, getters) {
    if (getters.superAdmin) {
      return true;
    }

    return (
      getters.isAssistant &&
      getters.permissions &&
      types.checkY(getters.permissions.update_user)
    );
  },
  canUpdateAuction(_, getters) {
    if (getters.superAdmin) {
      return true;
    }

    return (
      getters.isAssistant &&
      getters.permissions &&
      types.checkY(getters.permissions.update_auction)
    );
  },
  dashboard(_, getters) {
    return getters.isAdmin ? "admin.dashboard" : "user.dashboard";
  },
  addInsurance(_, getters) {
    return (
      !!getters.authorized && types.checkY(getters.authorized.add_insurance)
    );
  },
  hasInsurance(_, getters) {
    return !(!getters.authorized || !getters.authorized.insurance);
  },
  token(_, getters) {
    return !!getters.authorized ? getters.authorized.token : "";
  },
};

export default getters;
