const files_mixin = {
  emits: ["uploaded", "cleared"],
  data() {
    return {
      filePath: null,
      uploaded: false,
    };
  },
  watch: {
    uploaded(uploaded) {},
  },
  computed: {
    disabledBtn() {
      return !this.filePath;
    },
  },
  methods: {
    setImage(event) {
      // use it in case of normal HTML input
      let file = event.target.files[0];
      if (file) {
        this.filePath = URL.createObjectURL(file);
        URL.revokeObjectURL(file); // free memory
        this.uploaded = true;
      } else {
        this.filePath = null;
        this.uploaded = false;
      }
      if (!!this.$refs[event.target.name + "_error"])
        this.$refs[event.target.name + "_error"].textContent = "";
    },
    clearFilePath() {
      this.filePath = null;
    },
    setFilesCount(event) {
      const target = !!event ? event.target : "";

      if (!target) {
        return;
      }

      const count = $_(`#${target.id}_count`);
      const error = $_(`#${target.id}_error`);

      error.text("");
      count.removeClass("error text-success");

      // determine the count of uploaded images
      const text =
        target.files.length > 1 && target.files.length < 11
          ? messages.images
          : messages.image;

      if (target.files.length > 15) {
        target.value = "";
        count.text("يرجى تحديد 15 صور على الأكثر");
        count.addClass("error");
      } else if (target.files.length > 0) {
        count.text("نجح تحميل" + " ( " + target.files.length + " ) " + text);
        count.addClass("text-success");
        this.uploaded = true;
        this.$emit("uploaded", event);
      }
    },
    getFileName(id) {
      return $_("#" + id)
        .val()
        .replace(/C:\\fakepath\\/i, "");
    },
    clearInputFile(id) {
      $_(`#${id}`).val("");

      this.uploaded = false;
      const count = $_(`#${id}_count`);

      count.text("");
      count.removeClass("error text-success");

      this.$emit("cleared", id);
    },
    fileIsEmpty(id) {
      return (
        !document.getElementById(id) ||
        !document.getElementById(id).files ||
        document.getElementById(id).files.length === 0
      );
    },
    getFile(id) {
      const element = document.getElementById(id);
      return !!element ? element.files : "";
    },
    isImage(file) {
      if (!file || !file.type) {
        return false;
      }

      return file.type.split("/")[0] === "image";
    },
  },
};

export default files_mixin;
