<template>
  <div class="modal fade" :id="modalId">
    <div class="modal-dialog" :class="{ 'p-3': !full, ...modalClasses }">
      <div class="modal-content h-100 rounded-0" :class="modalContent">
        <div class="modal-header py-2 rounded-0" :class="headerBg">
          <h6 :class="titleColor">
            {{ title }}
          </h6>
          <button
            type="button"
            class="pm-0 close pointer"
            :class="titleColor"
            data-dismiss="modal"
            title="إغلاق"
          >
            <span class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body" :class="bodyClasses">
          <slot></slot>
        </div>
        <div class="modal-footer p-1" :class="footerClasses">
          <button
            :type="!!form ? 'submit' : 'button'"
            :form="form"
            class="btn rounded-0"
            :class="confirmBtn"
            @click="confirm"
          >
            {{ !!confirmText ? confirmText : "تأكيد" }}
          </button>
          <button
            type="button"
            class="btn rounded-0"
            :class="cancelBtn"
            :id="modalId + 'dismiss'"
            data-dismiss="modal"
            @click="dismiss"
          >
            {{ !!cancelText ? cancelText : "إلغاء" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {},
    titleColor: { default: "text-light" },
    targetId: {},
    headerBg: { default: "bg-info" },
    footerClasses: {},
    bodyClasses: {},
    modalContent: {},
    modalLg: { default: false, type: Boolean },
    modalXl: { default: false, type: Boolean },
    full: {},
    centered: {},
    confirmText: {},
    cancelText: {},
    confirmBtn: { default: "btn-info" },
    cancelBtn: { default: "btn-red" },
    form: { default: "", type: String },
  },
  emits: ["confirmed", "dismissed"],
  computed: {
    modalId() {
      return this.targetId ? this.targetId : "confirm-modal";
    },
    modalClasses() {
      return {
        "modal-full": this.full,
        "modal-dialog-centered": !!this.centered ? this.centered : true,
        "modal-lg": this.modalLg,
        "modal-xl": this.modalXl,
      };
    },
  },
  methods: {
    confirm() {
      // Use method to use it outside the component
      this.$emit("confirmed");
    },
    dismiss() {
      // Use method to use it outside the component
      this.$emit("dismissed");
    },
  },
};
</script>

<style scoped>
.modal {
  padding-right: 0 !important;
}

.close {
  font-size: 1rem !important;
}

.modal-content {
  border: none;
}

.modal-footer {
  width: 100%;
  justify-content: center;
}
</style>
