class dateMethods {
  dateValues(date) {
    if (!date || isNaN(new Date(date).getTime()))
      return false;

    date = new Date(new Date(date).toLocaleString('en', {timeZone: 'Asia/Qatar'}));

    const day = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();

    let month = date.getMonth() + 1;
    month = month < 10 ? ('0' + month) : month;

    const year = date.getFullYear();
    let hours = date.getHours();

    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) ? (hours % 12) : 12;

    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return {ampm, hours, minutes, seconds, year, month, day}
  }

  withoutTime(date, year_right = false) {
    date = this.dateValues(date);
    if (!date)
      return '';

    if (year_right)
      return `${date.day}-${date.month}-${date.year}`;
    return `${date.year}-${date.month}-${date.day}`;
  }

  withTime(date, year_right = false) {
    date = this.dateValues(date);
    if (!date)
      return '';

    date = `${date.year}-${date.month}-${date.day} ${date.hours}:${date.minutes} ${date.ampm}`
    const dir = year_right ? 'rtl' : 'ltr'
    return `<span dir="${dir}">${date}</span>`
  }

  finished(date) {
    return !!date && new Date(date).getTime() < new Date().getTime()
  }
}

const date = new dateMethods();
export default date;
