import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const auctions = {
  state() {
    return {
      indexAuctions: "",
    };
  },
  getters,
  actions,
  mutations,
};

export default auctions;
