<template>
  <teleport to="#app">
    <confirm-model
      title="تسجيل الخروج"
      target-id="confirm_sign_out"
      :centered="false"
      header-bg="bg-red"
      @confirmed="logout"
    >
      هل أنت متأكد من تسجيل الخروج؟
    </confirm-model>
  </teleport>
</template>

<script>
import ConfirmModel from "@/components/UI/ConfirmModel";

export default {
  components: { ConfirmModel },
  methods: {
    async logout() {
      this.hideModal("#confirm_sign_out");
      await this.$store.dispatch("logout");
    },
  },
};
</script>