<template>
  <div class="pm-0">
    <label>
      <slot></slot>
    </label>
    <FormInput
        :id="id"
        :name="imageName"
        :multiple="multiple"
        :text="text"
        type="file"
        :accept="accept"
        :error="false"
        :hidden="true"
        @changed="setFilesCount"
    />
    <button type="button" :class="buttonClass" @click="clickById(id)" :id="`${id}_btn`" v-if="!uploaded">
      <i class="fa fa-upload"></i> حمل
    </button>
    <button type="button" :class="buttonClass" disabled v-else>
      <i :class="disabledIcon"></i> حمل
    </button>
    <ErrorLabel :id="id" />
    <small class="error text-success font-weight-bolder" :id="`${id}_count`" v-show="showCount"></small>
    <button title="إزالة الملفات" class="btn btn-sm pm-0 m-2" v-if="uploaded && showClear" @click="clearInputFile(id)">
      <small class="link">إزالة</small>
    </button>
  </div>
</template>

<script>
import files_mixin from "@/mixins/files_mixin";
import FormInput from "@/components/Forms/FormInput";
import ErrorLabel from "@/components/UI/ErrorLabel";

export default {
  components: {ErrorLabel, FormInput},
  props: {
    id: {required: true},
    name: {},
    button: {},
    multiple: {},
    showClear: {default: true},
    showCount: {default: true},
    disabledIcon: {default: 'fa fa-upload'},
    shadow: {default: true},
    text: {},
    accept: {type: String, default: 'image/*'}
  },
  mixins: [files_mixin],
  computed: {
    buttonClass() {
      return (!!this.button ? this.button : 'btn-info') + ' ' + 'btn m-0 ' + (this.shadow ? 'nr-block-shadow' : 'nr-block');
    },
    imageName() {
      return !!this.name ? this.name : this.id;
    },
    empty() {
      return !this.id || this.fileIsEmpty(this.id)
    }
  }
}
</script>