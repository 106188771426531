<template>
  <div class="pm-0">
    <button v-if="showResults" class="btn btn-sm rounded-0 btn-danger" title="إلغاء البحث" @click="resetFilters">
      <span class="mr-1">إلغاء البحث</span>
      <i class="fa fa-times"></i>
    </button>
    <modal-btn v-else :target="`#${modalId}`" btn-class="btn-sm btn-info" title="بحث">
      <span class="mr-1">بحث</span>
      <i class="fa fa-search"></i>
    </modal-btn>
    <div v-if="showResults">
      <label class="d-block pm-0">نتائج البحث: </label>
      <small v-if="id">
        <strong>رقم المزاد: </strong>
        <span>{{ id }},&nbsp;</span>
      </small>
      <small v-if="claim_no">
        <strong>رقم الادعاء: </strong>
        <span>{{ claim_no }},&nbsp;</span>
      </small>
      <small v-if="plate_no">
        <strong>رقم اللوحة: </strong>
        <span>{{ plate_no }},&nbsp;</span>
      </small><small v-if="vin_no">
      <strong>رقم الشاصي: </strong>
      <span>{{ vin_no }},&nbsp;</span>
    </small>

      <small v-if="manufacturer">
        <strong>نوع السيارة: </strong>
        <span>{{ manufacturer }},&nbsp;</span>
      </small>
      <small v-if="model">
        <strong>موديل السيارة: </strong>
        <span>{{ model }},&nbsp;</span>
      </small>
      <small v-if="year">
        <strong>سنة الصنع: </strong>
        <span>{{ year }},&nbsp;</span>
      </small>
      <small v-if="start_date">
        <strong>تاريخ البدء: </strong>
        <span>{{ start_date }},&nbsp;</span>
      </small>
      <small v-if="finish_date">
        <strong>تاريخ الإنتهاء: </strong>
        <span>{{ finish_date }},&nbsp;</span>
      </small>
      <small v-if="company_id && companyName">
        <strong>شركة التأمين: </strong>
        <span>{{ companyName }},&nbsp;</span>
      </small>
    </div>
    <teleport to="#app">
      <confirm-model
          :target-id="modalId"
          title="بحث المزادات"
          confirm-text="بحث"
          cancel-btn="hide"
          :modal-lg="true"
          @confirmed="setFilters"
          ref="auctions_filter">
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <small class="link">رقم المزاد</small>
              <FormInput input-class="filter-inputs" id="id" type="number" placeholder="رقم المزاد"/>
            </div>
            <div class="col-sm-6">
              <small class="link">رقم الإدعاء</small>
              <FormInput input-class="filter-inputs" id="claim_no" placeholder="رقم الإدعاء"/>
            </div>
            <div class="col-sm-6">
              <small class="link">رقم اللوحة</small>
              <FormInput input-class="filter-inputs" id="plate_no" placeholder="رقم اللوحة"/>
            </div>

            <div class="col-sm-6">
              <small class="link">رقم الشاصي</small>
              <FormInput input-class="filter-inputs" id="vin_no" placeholder="رقم الشاصي"/>
            </div>
            <div class="col-sm-6">
              <small class="link"><abbr :title="menuSearch">نوع السيارة</abbr></small>
              <BootstrapSelect :options="manufacturers" id="manufacturer" title="نوع السيارة"
                               select-class="filter-inputs" @changed="setModelsList"/>
            </div>
            <div class="col-sm-6">
              <small class="link"><abbr :title="menuSearch">موديل السيارة</abbr></small>
              <BootstrapSelect id="model" title="موديل السيارة" select-class="filter-inputs" :options="models"/>
            </div>
            <div class="col-sm-6">
              <small class="link"><abbr :title="menuSearch">سنة الصنع</abbr></small>
              <BootstrapSelect id="year" title="سنة الصنع" select-class="filter-inputs" :options="years"/>
            </div>
            <div class="col-sm-6">
              <small class="link"><abbr :title="dateSearch">تاريخ البدء</abbr></small>
              <FormInput input-class="filter-inputs" id="start_date" type="date" placeholder="تاريخ البدء"/>
            </div>
            <div class="col-sm-6">
              <small class="link"><abbr :title="dateSearch">تاريخ الإنتهاء</abbr></small>
              <FormInput input-class="filter-inputs" id="finish_date" type="date" placeholder="تاريخ الإنتهاء"/>
            </div>
            <div class="col-sm-6">
              <small class="link"><abbr :title="menuSearch">شركة التأمين</abbr></small>
              <BootstrapSelect :options="companies" id="company_id" title="شركة التأمين" select-class="filter-inputs"/>
            </div>
          </div>
        </div>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormInput from "@/components/Forms/FormInput";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";
import cars from "@/mixins/cars";
import random from "@/common/random";
import companies from "@/mixins/companies";

export default {
  components: {BootstrapSelect, FormInput, ConfirmModel, ModalBtn},
  emits: ['filters-changed'],
  mixins: [cars, companies],
  data() {
    return {
      id: '',
      manufacturer: '',
      model: '',
      year: '',
      start_date: '',
      finish_date: '',
      company_id: '',
      claim_no: '',
      plate_no: '',
      vin_no: '',
    };
  },
  computed: {
    modalId() {
      return 'users-filter';
    },
    showResults() {
      return this.id ||
          this.manufacturer ||
          this.model ||
          this.year ||
          this.start_date ||
          this.finish_date ||
          this.company_id ||
          this.claim_no ||
          this.plate_no ||
          this.vin_no;
    },
    years() {
      return random.list(new Date().getFullYear())
    },
    menuSearch() {
      return 'يتم البحث من خلال إختيار قيمة من القائمة';
    },
    dateSearch() {
      return 'يتم البحث من خلال إختيار قيمة للتاريخ لا يشترط توافق التاريخ بالكامل';
    },
    companyName() {
      const company = this.companies.find(company => {
        return parseInt(company.id) === parseInt(this.company_id)
      })
      return !!company ? company.content : ''
    }
  },
  methods: {
    setFilters() {
      this.id = $_('#id').val()
      this.manufacturer = this.setManufacturerName($_('#manufacturer').val())
      this.model = this.setModelName($_('#model').val())
      this.year = $_('#year').val()
      this.start_date = $_('#start_date').val()
      this.finish_date = $_('#finish_date').val()
      this.company_id = $_('#company_id').val()
      this.claim_no = $_('#claim_no').val()
      this.plate_no = $_('#plate_no').val()
      this.vin_no = $_('#vin_no').val()

      if (this.showResults) {
        this.$emit('filters-changed', {
          id: this.id,
          manufacturer: this.manufacturer,
          model: this.model,
          year: this.year,
          start_date: this.start_date,
          finish_date: this.finish_date,
          company_id: this.company_id,
          claim_no: this.claim_no,
          plate_no: this.plate_no,
          vin_no: this.vin_no,
        });
        this.clearInputs()
        this.hideModal(`#${this.modalId}`);
      } else {
        http.infoMessage('يرجى تحديد فلتر واحد على الأقل')
      }
    },
    resetFilters() {
      this.id = '';
      this.manufacturer = '';
      this.model = '';
      this.year = '';
      this.start_date = '';
      this.finish_date = '';
      this.company_id = '';
      this.claim_no = '';
      this.plate_no = '';
      this.vin_no = '';
      this.clearInputs()
      this.$emit('filters-changed', '');
    },
    clearInputs() {
      $_('.filter-inputs').val('');
      $_('.filter-inputs.selectpicker').selectpicker('val', '');
    },
    clickEnter() {
      $_(document).on('keypress', '.filter-inputs', (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          if (this.$refs.auctions_filter) {
            this.$refs.auctions_filter.confirm()
          }
        }
      })
    }
  },
  async created() {
    await this.setManufacturerList();
    this.clickEnter()
  }
}
</script>

<style scoped>
button {
  min-width: 40px !important;
}
</style>
