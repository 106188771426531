<template>
  <div class="pm-0 position-relative">
    <select class="selectpicker form-control" data-select-on-tab="true" :data-title="title"
            :class="selectClass" :data-live-search="search" :required="required" :data-action-box="true"
            :data-size="dataSize" :data-live-search-placeholder="searchPlaceholder"
            :data-none-selected-text="noneSelectedText" data-none-results-text="لا يوجد {0}"
            :id="id" :name="inputName" :disabled="disabled || disableIfEmpty" @change="valueChanged"
            :multiple="multiple">
      <slot name="before"></slot>
      <option v-for="option in options" :key="!!option[value_key] ? option[value_key] : option" :class="optionClass"
              :value="!!option[value_key] ? option[value_key] : option">{{ option.content ? option.content : option }}
      </option>
      <!--this slot to add extra options-->
      <slot></slot>
      <option value="" v-if="emptyOption"></option>
    </select>
    <error-label v-if="error" :id="id"></error-label>
  </div>
</template>

<script>
import ErrorLabel from "@/components/UI/ErrorLabel";
import types from "@/common/types";

export default {
  components: {ErrorLabel},
  emits: ['changed'],
  props: {
    id: {required: true},
    value_key: {default: 'id'},
    content_key: {default: 'content'},
    name: {},
    selected: {},
    title: {type: String, default: 'إختر'},
    search: {type: Boolean, default: true},
    multiple: {type: Boolean, default: false},
    dataSize: {type: Number, default: 5},
    searchPlaceholder: {type: String, default: 'ابحث'},
    noneSelectedText: {type: String, default: 'لم يتم التحديد'},
    disabled: {type: Boolean, default: false},
    emptyDisabled: {type: Boolean, default: false},
    options: {default: []},
    selectBy: {default: 'id'},
    selectClass: {type: String},
    optionClass: {type: String},
    required: {type: Boolean},
    emptyOption: {default: true},
    error: {default: true},
  },
  computed: {
    menu() {
      return $_(`#${this.id}`);
    },
    inputName() {
      return this.name ? this.name : this.id;
    },
    hasOptions() {
      return this.options && (this.options.length > 0 || Number.isInteger(this.options));
    },
    disableIfEmpty() {
      return this.emptyDisabled && !this.hasOptions;
    },
    optionsArray() {
      return types.objectToArray(this.options) || []
    },
    selectedOption() {
      let selectedOption = this.selected;
      if (!selectedOption || !this.options || this.options.length === 0)
        return false;
      try {
        selectedOption = this.optionsArray.find((option) => {
          if (!!option[this.selectBy])
            return option[this.selectBy].toString() === selectedOption.toString();
          return option.toString() === selectedOption.toString();
        });
      } catch (e) {
        return false;
      }
      return selectedOption;
    }
  },
  methods: {
    valueChanged(e) {
      return this.$emit('changed', e.target.value);
    },
    refresh() {
      this.menu.selectpicker({
        style: 'form-control',
      }).selectpicker('refresh');
    },
  },
  mounted() {
    this.menu.selectpicker('val', '')
    this.refresh();

    if (this.selectedOption)
      this.menu.selectpicker('val', !!this.selectedOption[this.value_key] ? this.selectedOption[this.value_key] : this.selectedOption);
  },
  updated() {
    this.refresh();
  }
}
</script>
