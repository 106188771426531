require("./bootstrap");
require("./echo");
import { createApp } from "vue";
import App from "@/App.vue";
import "viewerjs/dist/viewer.css";
import "sweetalert2/dist/sweetalert2.min.css";

createApp(App)
  .use(require("./store").default)
  .use(require("./router").default)
  .use(require("v-viewer").default)
  .use(require("vue-sweetalert2"))
  .mixin(require("./mixins/commonMethods").default)
  .mixin(require("./mixins/sweetalert").default)
  .mount("#app");
