<template>
  <div class="card" :class="card">
    <div
        v-if="withHeader"
        class="card-header default d-flex justify-content-center align-items-center flex-wrap"
        :class="headerClasses"
    >
      <h1>
        <slot name="header"></slot>
      </h1>
    </div>

    <div class="card-body default" :class="bodyClasses">
      <slot name="body"></slot>
    </div>

    <div class="card-footer default" :class="footerClasses">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    card: {},
    header: {},
    body: {},
    footer: {},
    withHeader: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    headerClasses() {
      let classes = "bg-red w-100 text-center py-1 mb-2 text-light";
      return this.header ? classes + " " + this.header : classes;
    },
    bodyClasses() {
      const classes = "position-relative bg-e0";
      return this.body ? classes + " " + this.body : classes;
    },
    footerClasses() {
      return this.footer ? this.footer : "hide";
    },
  },
};
</script>

<style scoped>
.default {
  position: relative;
  text-align: center;
  border-radius: 0;
  border: none;
}

.card {
  background-color: transparent;
  width: 100% !important;
  /*height: 100% !important;*/
  border-radius: 0 !important;
  border: none !important;
}

.card-header {
  min-height: 2.5rem;
  line-height: 2.5rem;
  margin: 0 0 0.5rem 0 !important;
  padding: 0 !important;
}

.card-header h1 {
  font-size: 1rem;
  vertical-align: middle;
  line-height: normal;
}

@media screen and (max-width: 200px) {
  .default {
    padding: 0;
    margin: 0;
  }
}
</style>
