<template>
  <div class="container h-100">
    <div class="row justify-content-center align-items-center h-100">
      <div class="py-5" :class="columns">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      default: "col-lg-6 col-md-8",
    },
  },
};
</script>
