import rules from "./rules";
import messages from "./messages";
import files from "@/common/files";
import types from "@/common/types";

class Validation {
  rules;
  messages;

  constructor() {
    this.rules = rules;
    this.messages = messages;
  }

  validate(formId, rules = [], messages = []) {
    if (!formId) {
      return { status: false };
    }

    let errors = {};

    const form = document.getElementById(formId);
    if (!form) {
      return { status: false };
    }

    let data = new FormData(form);
    if (!data || !rules) {
      return { status: true, data };
    }

    for (const rule of rules) {
      const element = !!rule ? $_(`#${rule}`) : "";
      if (!!element) {
        const empty = files.isFile(rule)
          ? files.isEmpty(`#${rule}`)
          : !element.val();
        if (empty) {
          errors[rule] = messages[rule] || "هذا الحقل مطلوب";
        }
      }
    }

    if (types.isObject(errors)) {
      http.printErrors(errors);
    }

    return { status: !errors || Object.entries(errors).length === 0, data };
  }

  clearErrors() {
    $_(".form-error").text("");
    $_(".is-invalid").removeClass("is-invalid");
  }
}

const validation = new Validation();
export default validation;
