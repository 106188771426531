const messages = {
  auctions: {
    manufacturer: { required: required("نوع المركبة") },
    model: { required: required("موديل المركبة") },
    year: { required: required("سنة الصنع") },
    company_id: { required: required("شركة التأمين") },
    plate_no: { required: required("رقم اللوحة") },
    claim_no: { required: required("رقم الإدعاء") },
    auto_bid: { required: required("المزاود الآلي") },
    transferred: { required: required("نقل المركبة") },
    min_price: { required: required("موديل المركبة") },
    lowest_bid: { required: required("الحد الأدنى للبيع") },
    finish_date: { required: required("تاريخ الإنتهاء") },
    extra_minutes: { required: required("دقائق الزيادة") },
    opening_price: { required: required("سعر الإفتتاح") },
    main_photo: { required: required("الصورة الرئيسية") },
    receipt_form: { required: required("نموذج الإستلام") },
    auth_form: { required: required("نموذج التفويض") },
    city_id: { required: required("المحافظة") },
    area_id: { required: required("المنطقة") },
    licensed: { required: required("مرخصة لغاية") },
  },
};

function required(name) {
  return "حقل " + name + " مطلوب";
}

export default messages;
