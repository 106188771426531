const getters = {
  indexAuctions(state) {
    return state.indexAuctions;
  },
  movementMechanisms(_) {
    return ["دفع خلفي", "دفع أمامي", "دفع رباعي"];
  },
  movementVectors(_) {
    return ["أوتوماتيك", "عادي"];
  },
  carKeys() {
    return ["موجود", "غير موجود"];
  },
  bidsStatus() {
    return [
      { id: "y", content: "مفعل" },
      { id: "n", content: "غير مفعل" },
    ];
  },
  transferred() {
    return [
      { id: "y", content: "ونش المزاد" },
      { id: "n", content: "ونش خارجي" },
    ];
  },
  oils() {
    return ["بنزين", "ديزل", "كهرباء", "بنزين + كهرباء"];
  },
  lowestBids() {
    return [25, 50, 75, 100];
  },
};

export default getters;
