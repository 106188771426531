import types from "@/common/types"

class Arrays {
  // request_id
  removeById(items, itemId, search_key = 'id') {
    items = types.objectToArray(items)

    if (!itemId || !items || items.length === 0) return items
    // REMOVE THE DELETED ITEM FROM ITEMS LIST
    return items.filter((item) => {
      return parseInt(item[search_key]) !== parseInt(itemId)
    })
  }

  removeByValue(array, value) {
    if (!array) return []
    const index = !!value ? array.indexOf(value) : -1
    return index !== -1 ? array.splice(index, 1) : array
  }

  // update or push to array
  updateOrPush(arrayOfItems, item, unshift = true) {
    if (!arrayOfItems)
      return
    // IF THERE IS NO MOBILES PUSH THE CURRENT MOBILE DIRECTLY
    arrayOfItems = types.objectToArray(arrayOfItems)
    if (arrayOfItems.length === 0) {
      unshift ? arrayOfItems.unshift(item) : arrayOfItems.push(item)
    } else if (!!item) {
      // UPDATE THE SAME MOBILE BY IT`S INDEX
      const index = arrayOfItems.findIndex((element => parseInt(element.id) === parseInt(item.id)))
      if (index !== -1) {
        arrayOfItems[index] = item
      } else {
        unshift ? arrayOfItems.unshift(item) : arrayOfItems.push(item)
      }
    }

    return arrayOfItems
  }

  // GENERATE ARRAY OF NUMBER
  generateArray(number) {
    const list = []
    if (!!number && !isNaN(number))
      for (let i = 1; i <= number; i++)
        list.push(i)
    return list
  }

  empty(array) {
    return !array || array.length === 0
  }

  pushAtPosition(array, value, position) {
    if (!array || !Array.isArray(array))
      return []

    if ((array.length - 1) < position) {
      array.push(value)
    } else {
      array.splice(position, 0, value);
    }

    return array
  }
}

const arrays = new Arrays()
export default arrays
